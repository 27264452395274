import { get } from 'lodash';
import {
  FETCH_ADMISSIONS,
  FETCH_REGISTRATION_STATES,
  FETCH_REGISTRATION_STATES_SUCCESS,
  FETCH_REGISTRATION_STATES_ERROR,
  SEARCH_CHILD_IN_ADMISSIONS,
  FETCH_ADMISSIONS_WITH_FILTER,
  FETCH_REGISTRATIONS,
  FETCH_EXISTING_PARENT,
  FETCH_EXISTING_CHILD,
  FETCH_SUGGESTED_FEE_TIER,
  FETCH_ECDA_REGISTRATIONS,
} from '../actions/actionTypes';

const DEFAULT_STATE = {
  inProgress: false,
  data: null,
  error: null,
};

/**
 *
 * Example data findAllAdmission
 *
 * {
 *   data: {
 *     findAllAdmission: {
 *       totalCount: 802,
 *        data: [{
 *          ID: 13,
 *          firstname: "",
 *          lastname: "Hamizah Ibrahim",
 *          childFirstname: "",
 *          pendingRegisterSince: null,
 *          source: "Online",
 *          createdAt: "2019-08-10 00:00:00",
 *          updatedAt: "2020-05-08 11:51:28",
 *          fkEnquiryState: "cancelled",
 *          centre: {
 *            code: "TC2",
 *            label: "The Caterpillar's Cove @ Jurong East"
 *          },
 *          level: {
 *            label: "Infant"
 *          },
 *          program: {
 *            label: "Full Day"
 *          },
 *          enrollmentPlan: "2020-05-01 00:00:00",
 *          child: {
 *            ID: 596,
 *            firstname: "",
 *            lastname: "Baby Muhammad Fadhrees Suhaimi",
 *            birthCertificate: null,
 *            dateOfBirth: "2019-12-01 00:00:00"
 *          },
 *          user: {
 *            firstname: "",
 *            lastname: "Hamizah Ibrahim",
 *            mobilePhoneCountryCode: "+65000",
 *            mobilePhone: "40000939",
 *            email: "sn2dummy+40000939@gmail.com"
 *          },
 *          admissionStatus: {
 *            ID: 0,
 *            label: "UNKNOWN"
 *          }
 *        }]
 *     }
 *   }
 * }
 */

export const admissions = (state = {}, action) => {
  if (action.type === FETCH_ADMISSIONS) {
    const data = get(action, 'value.data.findAllAdmission');
    if (data) {
      return Object.assign({}, state, {
        ...action.value,
        data,
      });
    }
    return Object.assign({}, action.value);
  }
  return state;
};

export const admissionsWithFilter = (state = {}, action) => {
  if (action.type === FETCH_ADMISSIONS_WITH_FILTER) {
    const data = get(action, 'value.data.findAllAdmission');
    if (data) {
      return Object.assign({}, state, {
        ...action.value,
        data,
      });
    }
    return Object.assign({}, action.value);
  }
  return state;
};

/**
 *
 * Example data
 *
 * {
 *   data: {
 *     getRegistrations: {
 *       totalCount: 802,
 *       data: [
 *         {
 *         ID: 3,
 *         firstname: "",
 *         lastname: "Chai Li Fah",
 *         childFirstname: "",
 *         pendingRegisterSince: null,
 *         source: "Online",
 *         createdAt: "2019-09-01 00:00:00",
 *         updatedAt: "2020-11-10 12:37:07",
 *         fkEnquiryState: "waiting_list",
 *         "centre": {
 *           "code": "TC2",
 *           "label": "The Caterpillar's Cove @ Jurong East"
 *         },
 *         "level": {
 *           "label": "Infant"
 *         },
 *         "program": {
 *           "label": "Full Day"
 *         },
 *         "enrollmentPlan": "2020-06-01 00:00:00",
 *         "child": {
 *           "ID": 584,
 *           "firstname": "Hansel ",
 *           "lastname": "Tung Siang Quan",
 *           "birthCertificate": "T2002287B",
 *           "dateOfBirth": "2020-01-29 00:00:00"
 *         },
 *         "user": {
 *           "firstname": "Yi Jie",
 *           "lastname": "Koo ",
 *           "mobilePhoneCountryCode": "+65000",
 *           "mobilePhone": "40000928",
 *           "email": "sn2dummy+4001928@gmail.com"
 *         },
 *         "admissionStatus": {
 *           "ID": 0,
 *           "label": "UNKNOWN"
 *         }
 *       }]
 *     }
 *   }
 * }
 */

export const registrations = (state = {}, action) => {
  if (action.type === FETCH_REGISTRATIONS) {
    const data = get(action, 'value.data.getRegistrations');
    if (data) {
      return Object.assign({}, state, {
        ...action.value,
        data,
      });
    }
    return Object.assign({}, action.value);
  }
  return state;
};

export const ecdaRegistrations = (state = {}, action) => {
  if (action.type === FETCH_ECDA_REGISTRATIONS) {
    const data = get(action, 'value.data.getECDARegistrations');
    if (data) {
      return Object.assign({}, state, {
        ...action.value,
        data,
      });
    }
    return Object.assign({}, action.value);
  }
  return state;
};

/**
 *
 * Example data
 *
 * {
 *  "data": {
 *    "getExistingParent": {
 *      "ID": 665,
 *      "firstname": "",
 *      "lastname": "Chai Li Fah",
 *      "email": "sn2dummy+40000664@gmail.com",
 *      "mobilePhoneCountryCode": "+65000",
 *      "mobilePhone": "40000664"
 *    }
 *  }
 *}
 */

export const existingParent = (state = {}, action) => {
  if (action.type === FETCH_EXISTING_PARENT) {
    const data = get(action, 'value.data.getExistingParent');
    if (data) {
      return Object.assign({}, state, {
        ...action.value,
        data,
      });
    }
    return Object.assign({}, action.value);
  }
  return state;
};

/**
 *
 * Example data
 *
 *{
 *  "data": {
 *    "getExistingChild": {
 *      "child": {
 *        "ID": 586,
 *        "firstname": "",
 *        "lastname": "Hazel Felise Lim",
 *        "birthCertificate": "T2002208B",
 *        "dateOfBirth": "2020-01-23 00:00:00",
 *        "enquiries": {
 *          "data": [
 *            {
 *              "enrollmentPlan": "2021-01-01 00:00:00",
 *              "centre": {
 *                "ID": 1,
 *                "label": "The Caterpillar's Cove @ Jurong East"
 *              },
 *              "level": {
 *                "ID": 16,
 *                "label": "Infant"
 *              },
 *              "program": {
 *                "ID": 9,
 *                "label": "Half Day (AM)"
 *              }
 *            }
 *          ]
 *        }
 *      },
 *      "mainApplicant": {
 *        "ID": 665,
 *        "firstname": "",
 *        "lastname": "Chai Li Fah",
 *        "email": "sn2dummy+40000664@gmail.com",
 *        "mobilePhoneCountryCode": "+65000",
 *        "mobilePhone": "40000664"
 *      }
 *    }
 *  }
 *}
 */

export const existingChild = (state = {}, action) => {
  if (action.type === FETCH_EXISTING_CHILD) {
    const data = get(action, 'value.data.getExistingChild');
    if (data) {
      return Object.assign({}, state, {
        ...action.value,
        data,
      });
    }
    return Object.assign({}, action.value);
  }
  return state;
};

/**
 *
 * Example data
 *{
 *  "data": {
 *    "getAdmissionFilters": {
 *      "stages": [
 *        {
 *          "code": "trial",
 *          "name": "Trial",
 *          "statuses": [
 *            {
 *              "code": "pending_trial",
 *              "name": "Pending Trial Period"
 *            },
 *          ]
 *        },
 *        {
 *          "code": "enrolment",
 *          "name": "Enrolment",
 *          "statuses": [
 *            {
 *              "code": "pre_registration",
 *              "name": "Pre Registration"
 *            },
 *          ]
 *        }
 *      ]
 *    }
 *  }
 *}
 *
 */

export const admissionFilters = (state = DEFAULT_STATE, action) => {
  switch (action.type) {
    case FETCH_REGISTRATION_STATES:
      return {
        ...state,
        ...action.value,
      };
    case FETCH_REGISTRATION_STATES_SUCCESS:
      return {
        ...state,
        ...action.value,
      };
    case FETCH_REGISTRATION_STATES_ERROR:
      return {
        ...state,
        ...action.value,
      };
    default:
      return state;
  }
};

/**
 *
 * Example data
 *{
 *  "data": {
 *    "searchAdmissions": {
 *      "totalCount": 0,
 *      "data": [
 *        {
 *          "child": {
 *            "ID": 1266,
 *            "firstname": "Hal",
 *            "lastname": "Fritsch",
 *            "birthCertificate": "A8508011Z"
 *          },
 *          "user": {
 *            "ID": 1907,
 *            "firstname": "Alisa",
 *            "lastname": "Fritsch",
 *            "mobilePhoneCountryCode": "+65",
 *            "mobilePhone": "84836314",
 *            "email": "test_boehm_20oct150506@mailinator.com"
 *          }
 *        }
 *      ]
 *    }
 *  }
 *}
 */

export const searchChildInAdmissions = (state = {}, action) => {
  if (action.type === SEARCH_CHILD_IN_ADMISSIONS) {
    const data = get(action, 'value.data.searchAdmissions.data');
    if (data) {
      return Object.assign({}, state, {
        ...action.value,
        data,
      });
    }
    return Object.assign({}, action.value);
  }
  return state;
};

export const suggestedFeeTier = (state = {}, action) => {
  if (action.type === FETCH_SUGGESTED_FEE_TIER) {
    const data = get(
      action,
      'value.data.getSuggestedFeeTierForRegistrationChild.data'
    );
    if (data) {
      return Object.assign({}, state, {
        ...action.value,
        data,
      });
    }
    return Object.assign({}, action.value);
  }
  return state;
};

export default admissions;

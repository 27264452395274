import React, { useState, useRef } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import moment from 'moment';
import ReactMarkdown from 'components/common/ReactMarkDown';
import { withTheme } from '@material-ui/core/styles';
import ArrowUpward from '@material-ui/icons/ArrowUpward';
import compose from 'recompose/compose';
import { withTranslation, Trans } from 'react-i18next';
import { Button, Grid } from '@material-ui/core';
import get from 'lodash/get';
import CommonModal from './Modal';
import Text from './Text';
import { scrollToTop } from '../../utils';
import { StyledText, useStyles } from './style';
import { VULNERABILITY_PROGRAMME_URL } from '../../utils/constants';

const Footer = ({ theme, nameSchool, t, isParent }) => {
  const classes = useStyles();
  const [isTermsModalOpen, setTermsModalOpen] = useState(false);
  const termsEpRef = useRef(null);
  const termsRegistrationRef = useRef(null);

  return (
    <div
      className="s7t-footer"
      style={{ backgroundColor: theme.footerColor, textAlign: 'center' }}
    >
      <Text label className="s7t-footer-desc" fontSize="12px">
        <Trans i18nKey="common.copyright" /> {moment().year()} &#169;
        {` ${nameSchool || t('common.defaultFooterName')}`}.{' '}
        <Trans i18nKey="common.allRightsReserved" />
        {isParent && (
          <>
            {' '}
            <Text
              label
              style={{ textDecoration: 'underline' }}
              fontSize="12px"
              onClick={() => setTermsModalOpen(true)}
            >
              {t('common.termsAndConditions')}
            </Text>
          </>
        )}
        .{' '}
        <a
          target="_blank"
          rel="noreferrer"
          alt={t('common.vulnerabilityProgramme')}
          href={VULNERABILITY_PROGRAMME_URL}
        >
          <Trans i18nKey="common.vulnerabilityProgramme" />
        </a>
      </Text>
      <Button
        onClick={() => scrollToTop()}
        variant="contained"
        color="primary"
        className="s7t-up"
      >
        <ArrowUpward />
      </Button>
      <CommonModal
        isOpen={isTermsModalOpen}
        fullWidth
        showClose
        maxWidth="md"
        hideBtns
        contentStyle={classes.contentStyle}
        onClose={() => setTermsModalOpen(false)}
        dialogTitle={
          <Grid container direction="column">
            <Grid item>
              <Text variant="h3" bold>
                {t('common.termsAndConditions')}
              </Text>
            </Grid>
            <Grid item>
              <StyledText
                onClick={() =>
                  termsEpRef?.current?.scrollIntoView({ behavior: 'smooth' })
                }
              >
                {t('enrichment_programme.enrichmentProgram')}
              </StyledText>
            </Grid>
            <Grid item>
              <StyledText
                onClick={() =>
                  termsRegistrationRef?.current?.scrollIntoView({
                    behavior: 'smooth',
                  })
                }
              >
                {t('registration.registration')}
              </StyledText>
            </Grid>
          </Grid>
        }
        dialogContent={
          <Grid container direction="column">
            <Grid item ref={termsEpRef}>
              <Text variant="h4">
                {t('enrichment_programme.enrichmentProgram')}
              </Text>
            </Grid>
            <Grid item>
              <ReactMarkdown
                style={{ fontSize: '16px' }}
                source={t('enrichment_programme.termsAndConditionsEP')}
                escapeHtml={false}
              />
            </Grid>
            <Grid item ref={termsRegistrationRef}>
              <Text variant="h4">{t('registration.registration')}</Text>
            </Grid>
            <Grid item>
              <ReactMarkdown
                style={{ fontSize: '16px' }}
                source={`${t('enrichment_class.paymentTermsAndCondition')}
                  ${t('enrichment_class.paymentTermsAndConditionOnline')}
                `}
                escapeHtml={false}
              />
            </Grid>
          </Grid>
        }
      />
    </div>
  );
};

const mapStateToProps = state => ({
  nameSchool: get(
    state,
    'userDetails.data.userAccessControls.data[0].school.name'
  ),
  isParent: !get(
    state,
    'userDetails.data.userRoleRelations.data[0].role.isStaff'
  ),
});

const mapDispatchToProps = {};

export default compose(
  withTranslation(),
  withTheme
)(withRouter(connect(mapStateToProps, mapDispatchToProps)(Footer)));

import gql from 'graphql-tag';

// eslint-disable-next-line import/prefer-default-export
export const GET_PSS_QUESTIONS = gql`
  query getSurveyQuestions($surveyID: Int!, $childID: Int!) {
    getSurveyQuestions(surveyID: $surveyID, childID: $childID) {
      ID
      questionNo
      sectionLabel
      sectionTitle
      question
      isAllowMultiple
      questionType
      responseID
      response
      answerID
      answer
    }
  }
`;

export const GET_SURVEY_FOR_PARENT = gql`
  query getSurveyForParent($parentID: Int!, $time: Datetime!, $source: String) {
    getSurveyForParent(parentID: $parentID, source: $source) {
      closureDate
      childSurveys {
        child {
          ID
          firstname
          lastname
          fullname
          imageKey
          imageKeyPresignedURL
          childClassAt(time: $time) {
            class {
              ID
              label
            }
          }
        }
        centreLabel
        childLevel
        lastUpdateAt
        lastUpdateBy {
          ID
          firstname
          lastname
          email
          imageKey
        }
        completedAt
        completedBy {
          ID
          firstname
          lastname
          email
          imageKey
        }
        surveyLink
      }
    }
  }
`;

export const SAVE_SURVEY_RESPONSE = gql`
  mutation updateSurveyResponse(
    $surveyID: Int!
    $childID: Int!
    $parentID: Int!
    $hashKey: String!
    $surveyResponse: [QuestionAnswer]!
    $isCompleted: Boolean
    $source: String
  ) {
    updateSurveyResponse(
      surveyID: $surveyID
      childID: $childID
      parentID: $parentID
      hashKey: $hashKey
      surveyResponse: $surveyResponse
      isCompleted: $isCompleted
      source: $source
    )
  }
`;

export const PSS_SURVEY_CONFIG = gql`
  query getSurveyConfig($surveyID: Int!, $childID: Int!) {
    getSurveyConfig(surveyID: $surveyID, childID: $childID) {
      survey {
        closureDate
      }
      surveyRecipient {
        completedAt
      }
    }
  }
`;

export const PSS_SURVEY_NOTIFICATION_CONFIG = gql`
  query getSurveyNotificationConfig {
    getSurveyNotificationConfig {
      totalCount
      data {
        ID
        key
        value
        createdAt
        createdBy
        active
      }
    }
  }
`;

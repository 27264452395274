import { SkApolloRequest } from '../../utils/apolloUtils';
import { parseGraphQLError } from '../../utils';
import {
  SAVE_ECDA_REG_GIRO_APPLICATION,
  GET_ID_DOCUMENT_TYPE_CONFIG_ECDA_GIRO_URL,
  MARK_GIRO_AS_COMPLETED_ECDA,
  SKIP_GIRO_ECDA,
  GET_ECDA_REGISTRATION_CHILD_BALANCE_SHEET,
  TRANSFER_ECDA_REG_CHILD_BALANCE,
  FIND_ALL_ECDA_REG_CHILD_REFUNDS,
} from '../models/financeModelECDA';
import {
  FETCH_ALL_REG_CHILD_REFUNDS_ERROR,
  FETCH_ALL_REG_CHILD_REFUNDS_LOADING,
  FETCH_ALL_REG_CHILD_REFUNDS_SUCCESS,
} from './actionTypes';

export const saveECDARegGiroApplication = reqData => async () => {
  try {
    const data = await SkApolloRequest({
      params: {
        mutation: SAVE_ECDA_REG_GIRO_APPLICATION,
        variables: reqData,
      },
      type: 'mutation',
    });
    return data;
  } catch (ex) {
    return parseGraphQLError(ex);
  }
};

export const getECDAGiroFormUploadURL = reqData => async () => {
  try {
    const data = await SkApolloRequest({
      params: {
        mutation: GET_ID_DOCUMENT_TYPE_CONFIG_ECDA_GIRO_URL,
        variables: reqData,
      },
      type: 'mutation',
    });
    return data;
  } catch (ex) {
    return parseGraphQLError(ex);
  }
};

export const completeGiroSetupECDA = IDEcdaRegistration => async () => {
  try {
    return await SkApolloRequest({
      params: {
        mutation: MARK_GIRO_AS_COMPLETED_ECDA,
        variables: { IDEcdaRegistration },
      },
      type: 'mutation',
    });
  } catch (ex) {
    throw ex;
  }
};

export const skipGiroForECDA = reqData => async () => {
  try {
    const data = await SkApolloRequest({
      params: {
        mutation: SKIP_GIRO_ECDA,
        variables: reqData,
      },
      type: 'mutation',
    });
    return data;
  } catch (ex) {
    return parseGraphQLError(ex);
  }
};

export const getEcdaRegistrationChildBalanceSheet = async reqData => {
  try {
    const data = await SkApolloRequest({
      params: {
        query: GET_ECDA_REGISTRATION_CHILD_BALANCE_SHEET,
        variables: reqData,
      },
      type: 'query',
    });
    return data;
  } catch (ex) {
    return parseGraphQLError(ex);
  }
};

export const transferEcdaRegistrationChildBalance = async reqData => {
  try {
    const data = await SkApolloRequest({
      params: {
        mutation: TRANSFER_ECDA_REG_CHILD_BALANCE,
        variables: { dto: reqData },
      },
      type: 'mutation',
    });
    return data;
  } catch (ex) {
    return parseGraphQLError(ex);
  }
};

const allECDARegChildRefundsLoading = () => ({
  type: FETCH_ALL_REG_CHILD_REFUNDS_LOADING,
  key: 'allECDARegChildRefunds',
  value: {
    inProgress: true,
  },
});

const allECDARegChildRefundsSuccess = data => ({
  type: FETCH_ALL_REG_CHILD_REFUNDS_SUCCESS,
  key: 'allECDARegChildRefunds',
  value: {
    inProgress: false,
    data,
  },
});

const allECDARegChildRefundsError = error => ({
  type: FETCH_ALL_REG_CHILD_REFUNDS_ERROR,
  key: 'allECDARegChildRefunds',
  value: {
    inProgress: false,
    data: null,
    error,
  },
});

export const getECDARegistrationRefundChildBalance =
  reqData => async dispatch => {
    dispatch(allECDARegChildRefundsLoading());
    try {
      const data = await SkApolloRequest({
        params: {
          query: FIND_ALL_ECDA_REG_CHILD_REFUNDS,
          variables: reqData,
        },
        type: 'query',
      });
      if (data.success) {
        dispatch(allECDARegChildRefundsSuccess(data.data));
      } else {
        dispatch(allECDARegChildRefundsError(data.error));
      }
    } catch (ex) {
      dispatch(allECDARegChildRefundsError(ex.ex.message));
    }
  };

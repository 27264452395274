import React, { useEffect } from 'react';
import URLs from './URLs';

const AppRedirect = () => {
  const redirectToScheme = url => {
    window.location.assign(url);
  };

  const handleAndroidRedirect = (userAgent, appSchemeURL, timeout) => {
    if (/Huawei|HONOR/i.test(userAgent)) {
      redirectToScheme(appSchemeURL);
      setTimeout(() => window.location.assign(URLs.huawei), timeout);
    } else {
      redirectToScheme(appSchemeURL);
      setTimeout(() => window.location.assign(URLs.android), timeout);
    }
  };

  const handleIOSRedirect = (appSchemeURL, timeout) => {
    let appOpened = false;

    window.location.href = appSchemeURL;

    const timeoutId = setTimeout(() => {
      if (!appOpened) {
        const iframe = document.createElement('iframe');
        iframe.style.display = 'none';
        iframe.src = appSchemeURL;
        document.body.appendChild(iframe);

        setTimeout(() => {
          if (iframe) {
            document.body.removeChild(iframe);
          }
        }, 1000);

        const storeTimeoutId = setTimeout(() => {
          if (!appOpened) {
            window.location.href = URLs.ios;
          }
        }, timeout);

        const onVisibilityChange = () => {
          if (
            document.visibilityState === 'hidden' ||
            document.visibilityState === 'unloaded'
          ) {
            appOpened = true;
            clearTimeout(storeTimeoutId);
          }
        };

        document.addEventListener('visibilitychange', onVisibilityChange, {
          once: true,
        });
        window.addEventListener('pagehide', onVisibilityChange, { once: true });
      }
    }, timeout);

    const onVisibilityChange = () => {
      if (
        document.visibilityState === 'hidden' ||
        document.visibilityState === 'unloaded'
      ) {
        appOpened = true;
        clearTimeout(timeoutId);
      }
    };

    document.addEventListener('visibilitychange', onVisibilityChange, {
      once: true,
    });
    window.addEventListener('pagehide', onVisibilityChange, { once: true });
  };

  const redirectUserBasedOnPlatform = () => {
    const userAgent = navigator.userAgent || navigator.vendor || window.opera;
    const appSchemeURL = URLs.appSchemeURL;
    const timeout = 2000; // Set timeout to 1.5 seconds

    if (/android/i.test(userAgent)) {
      handleAndroidRedirect(userAgent, appSchemeURL, timeout);
    } else if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
      handleIOSRedirect(appSchemeURL, timeout);
    } else {
      redirectToScheme(appSchemeURL);
    }
  };

  useEffect(() => {
    redirectUserBasedOnPlatform();
  }, []);

  return (
    <div style={{ textAlign: 'center', marginTop: '50px' }}>
      <p>Redirecting you to the appropriate store...</p>
    </div>
  );
};

export default AppRedirect;

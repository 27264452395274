import React from 'react';
import { get } from 'lodash';
import { Grid } from '@material-ui/core';
import FieldWrapper from './FieldWrapper';
import { TagFieldWrapper } from './style';

export const GroupTagField = ({ messages, label }) => (
  <FieldWrapper label={label} style={{ marigin: '0px 10px 0px 10px' }}>
    <Grid container className="w3-padding-16">
      {get(messages, 'length') &&
        messages.map(item => {
          const text = item.label;
          return <TagFieldWrapper item>{text}</TagFieldWrapper>;
        })}
    </Grid>
  </FieldWrapper>
);

export default GroupTagField;

import { get, isEmpty } from 'lodash';
import {
  FETCH_DISCOUNT_GROUPS,
  FETCH_DISCOUNT_OPTIONS_CONFIG,
  FETCH_ALL_APPLICABLE_CHILD_DISCOUNTS,
  FETCH_ALL_CHILD_DISCOUNTS,
  ACTION_REMOVE_CHILD_DISCOUNT,
  ACTION_DEACTIVATE_CHILD_DISCOUNT,
  FETCH_ALL_APPLICABLE_ENQUIRY_DISCOUNTS,
  FETCH_ALL_CHILD_DISCOUNTS_REGISTRATION,
  FETCH_ALL_CHILD_DISCOUNTS_ECDA_REGISTRATION,
  FETCH_ALL_APPLICABLE_ENQUIRY_DISCOUNTS_ECDA,
} from '../actions/actionTypes';

export const fetchAllApplicableChildDiscount = (state = {}, action) => {
  if (action.type === FETCH_ALL_APPLICABLE_CHILD_DISCOUNTS) {
    const data = get(action, 'value.data.findAllApplicableChildDiscounts');
    if (data) {
      return Object.assign({}, state, {
        ...action.value,
        data,
      });
    }
    return Object.assign({}, state, action.value);
  }

  return state;
};

export const fetchAllApplicableRegistrationDiscount = (state = {}, action) => {
  if (action.type === FETCH_ALL_APPLICABLE_ENQUIRY_DISCOUNTS) {
    const data = get(
      action,
      'value.data.findAllApplicableRegistrationChildDiscounts'
    );
    if (data) {
      return Object.assign({}, state, {
        ...action.value,
        data,
      });
    }
    return Object.assign({}, state, action.value);
  }

  return state;
};

export const fetchAllApplicableEcdaRegistrationDiscount = (
  state = {},
  action
) => {
  if (action.type === FETCH_ALL_APPLICABLE_ENQUIRY_DISCOUNTS_ECDA) {
    const data = get(
      action,
      'value.data.findAllApplicableEcdaRegistrationChildDiscounts'
    );
    if (data) {
      return Object.assign({}, state, {
        ...action.value,
        data,
      });
    }
    return Object.assign({}, state, action.value);
  }

  return state;
};

export const fetchAllChildDiscounts = (state = {}, action) => {
  if (action.type === FETCH_ALL_CHILD_DISCOUNTS) {
    const data = get(action, 'value.data.getAllChildDiscounts');
    if (data) {
      return Object.assign({}, state, {
        ...action.value,
        data,
      });
    }
    return Object.assign({}, state, action.value);
  }

  return state;
};

export const discountRegistration = (state = {}, action) => {
  if (action.type === FETCH_ALL_CHILD_DISCOUNTS_REGISTRATION) {
    const data = get(action, 'value.data.getAllRegistrationChildDiscounts');
    if (data) {
      return Object.assign({}, state, {
        ...action.value,
        data,
      });
    }
    return Object.assign({}, state, action.value);
  }

  return state;
};

export const discountEcdaRegistration = (state = {}, action) => {
  if (action.type === FETCH_ALL_CHILD_DISCOUNTS_ECDA_REGISTRATION) {
    const data = get(action, 'value.data.getAllEcdaRegistrationChildDiscounts');
    if (data) {
      return Object.assign({}, state, {
        ...action.value,
        data,
      });
    }
    return Object.assign({}, state, action.value);
  }

  return state;
};

export const discountGroups = (state = {}, action) => {
  if (action.type === FETCH_DISCOUNT_GROUPS) {
    const data = get(action, 'value.data.getAllDiscountItemGroup.data');
    if (data) {
      return Object.assign({}, state, {
        ...action.value,
        data,
        totalCount: get(
          action,
          'value.data.getAllDiscountItemGroup.totalCount',
          0
        ),
      });
    }
    return Object.assign({}, state, action.value);
  }

  return state;
};

export const discountOptionConfig = (state = {}, action) => {
  if (action.type === FETCH_DISCOUNT_OPTIONS_CONFIG) {
    const response = action.value.data;
    if (!isEmpty(response)) {
      const configs = {};

      const categoryKeys = Object.keys(response);
      categoryKeys.forEach(key => {
        configs[key] = response[key].data;
      });

      return Object.assign({}, state, {
        ...action.value,
        data: configs,
      });
    }

    return Object.assign({}, state, action.value);
  }

  return state;
};

export const removeChildDiscoutConfig = (state = {}, action) => {
  if (action.type === ACTION_REMOVE_CHILD_DISCOUNT) {
    const response = action.value.data;
    if (response) {
      return Object.assign({}, state, {
        ...action.value,
        data: response,
      });
    }
    return Object.assign({}, state, action.value);
  }
  return state;
};

export const deactivateChildDiscoutConfig = (state = {}, action) => {
  if (action.type === ACTION_DEACTIVATE_CHILD_DISCOUNT) {
    const response = action.value.data;
    if (response) {
      return Object.assign({}, state, {
        ...action.value,
        data: response,
      });
    }
    return Object.assign({}, state, action.value);
  }

  return state;
};

import React, { useEffect } from 'react';
import { Grid } from '@material-ui/core';
import CheckCircleRoundedIcon from '@material-ui/icons/CheckCircleRounded';
import ReactMarkdown from 'components/common/ReactMarkDown';
import useStyles from '../style';
import Text from '../../../common/Text';

const SurveySuccessScreen = props => {
  const { childName, surveyStatusMessage, t, successIcon = true } = props;
  const classes = useStyles();

  useEffect(() => {
    setTimeout(() => {
      window.scrollTo({
        top: 0,
        behavior: 'smooth',
      });
    }, 100);
  }, []);

  return (
    <Grid direction="column" className="w3-center s7t-pss-success-screen">
      <Grid className="w3-margin-top">
        {successIcon && (
          <CheckCircleRoundedIcon
            style={{ fontSize: 80 }}
            className={classes.checkCircleIcon}
          />
        )}
      </Grid>
      {childName ? (
        <>
          <Grid className={classes.marginBottom10}>
            <Text className="s7t-pss-success-txt-1">
              {t('common.surveyCompletedFor', { childName })}
            </Text>
          </Grid>
          <Grid>
            <Text className="s7t-pss-success-txt-2">
              Thank you for participating in our survey. Your feedback is highly
              appreciated.
            </Text>
            <br />
            <Text className="s7t-pss-success-txt-2">
              谢谢您参与我们的调查。我们非常感激您的反馈。
            </Text>
          </Grid>
        </>
      ) : (
        <>
          <Grid className={classes.marginBottom10}>
            <ReactMarkdown
              className="s7t-pss-success-txt-2"
              source={surveyStatusMessage}
              escapeHtml={false}
            />
          </Grid>
        </>
      )}
    </Grid>
  );
};

export default SurveySuccessScreen;

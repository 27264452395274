import { get } from 'lodash';
import { FETCH_ECDA_REG_DOCUMENTS_TO_UPLOAD } from '../actions/actionTypes';

// eslint-disable-next-line import/prefer-default-export
export const fetchECDARegistrationDocumentsToUpload = (state = {}, action) => {
  if (action.type === FETCH_ECDA_REG_DOCUMENTS_TO_UPLOAD) {
    const data = get(action, 'value.data.getRequiredECDARegDocuments.data');
    if (data) {
      return Object.assign({}, state, {
        ...action.value,
        data,
      });
    }

    return Object.assign({}, state, action.value);
  }

  return state;
};

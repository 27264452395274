import React from 'react';
import { Grid, Avatar } from '@material-ui/core';
import get from 'lodash/get';
import PropTypes from 'prop-types';
import Text from '../../../common/Text';

const SearchResultComponent = ({ bc, name, cdnUrl, imageKey, childClass }) => {
  const childImageKey = imageKey?.includes('http')
    ? imageKey
    : `${cdnUrl}/${imageKey || 'ChildProfile/profile_pic_default.png'}`;
  return (
    <Grid container spacing={1}>
      <Grid item xs={2} className="w3-center s7t-auto-margin">
        <Avatar
          alt="child"
          src={childImageKey}
          width="40"
          style={{ borderRadius: '50%' }}
        >
          {get(name, '[0]')}
        </Avatar>
      </Grid>
      <Grid item xs={10}>
        <Grid container>
          <Grid item xs={12} md={6}>
            <Text body bold className="white-hover">
              {name}
            </Text>
            <Text label className="white-hover">
              {childClass}
            </Text>
          </Grid>
          <Grid item xs={12} md={6} className="s7t-auto-margin">
            <Text body className="white-hover">
              {bc}
            </Text>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

SearchResultComponent.defaultProps = {
  imageKey: '',
  bc: '',
  cdnUrl: '',
  childClass: '',
};

SearchResultComponent.propTypes = {
  imageKey: PropTypes.string,
  bc: PropTypes.string,
  cdnUrl: PropTypes.string,
  childClass: PropTypes.string,
};

export default SearchResultComponent;

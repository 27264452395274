// eslint-disable-next-line no-unused-vars
import { get } from 'lodash';
import moment from 'moment';
import { useState, useEffect } from 'react';
import { useParams } from 'react-router';
import { DATE_PICKER_FORMAT } from 'utils/constants';
import { decodePssSurveyLink } from '../../../utils';

const Handler = props => {
  const { fetchPssQuestions, getPssSurveyConfig } = props;
  const [questions, setQuestions] = useState({});
  const [currentQuestionNo, setCurrentQuestionNo] = useState(1);
  const [centreID, setCentreID] = useState(null);
  const [childID, setChildID] = useState(null);
  const [parentID, setParentID] = useState(null);
  const [schoolID, setSchoolID] = useState(null);
  const [serviceLevel, setServiceLevel] = useState(null);
  const [surveyID, setSurveyID] = useState(null);
  const [showSurveySuccessScreen, setShowSurveySuccessScreen] = useState(false);
  const [showQuestions, setShowQuestions] = useState(false);
  const [showWelcomeScreen, setShowWelcomeScreen] = useState(false);
  const [surveyExpired, setSurveyExpired] = useState(false);
  const [surveyCompleted, setSurveyCompleted] = useState(false);

  const params = useParams();

  const getSurveyConfig = async requestData => {
    const surveyConfig = await getPssSurveyConfig(requestData);
    if (get(surveyConfig, 'data.getSurveyConfig.surveyRecipient.completedAt')) {
      setSurveyCompleted(true);
    }
    if (
      get(surveyConfig, 'data.getSurveyConfig.survey.closureDate') &&
      moment().format(DATE_PICKER_FORMAT) >
        get(surveyConfig, 'data.getSurveyConfig.survey.closureDate')
    ) {
      setSurveyExpired(true);
    }
  };

  const showQuestionsOrWelcomeScreen = resp => {
    const initialAnswersArray = [];
    let checkCurrentQuestionStatus = true;
    get(resp, 'data.getSurveyQuestions', []).map((item, idx) => {
      if (!get(item, 'answer') && checkCurrentQuestionStatus) {
        setCurrentQuestionNo(get(item, 'questionNo'));
        checkCurrentQuestionStatus = false;
      }
      if (get(item, 'answer')) {
        initialAnswersArray.push({
          ID: get(item, 'ID'),
          response: get(item, 'answer'),
          responseID: get(item, 'answerID'),
        });
        if (
          checkCurrentQuestionStatus &&
          get(resp, 'data.getSurveyQuestions.length', 0) === idx + 1
        ) {
          setCurrentQuestionNo(get(item, 'questionNo'));
        }
      }
    });
    if (get(initialAnswersArray, 'length', 0) > 0) {
      setShowQuestions(true);
    } else {
      setShowWelcomeScreen(true);
    }
  };

  const getChildAndSurveyId = () => {
    const decodedValue = decodePssSurveyLink(params?.id);
    const splitDecodedValue = decodedValue.split('&');
    let childId = null;
    let surveyId = null;
    splitDecodedValue.forEach(item => {
      const splitValue = item.split('=');
      if (splitValue[0] === 'centreID') {
        setCentreID(parseInt(splitValue[1], 10));
      } else if (splitValue[0] === 'childID') {
        setChildID(parseInt(splitValue[1], 10));
        childId = parseInt(splitValue[1], 10);
      } else if (splitValue[0] === 'parentID') {
        setParentID(parseInt(splitValue[1], 10));
      } else if (splitValue[0] === 'schoolID') {
        setSchoolID(parseInt(splitValue[1], 10));
      } else if (splitValue[0] === 'serviceLevel') {
        let updateServiceLevel = splitValue[1] || '';
        updateServiceLevel = updateServiceLevel.replace(/\+/g, ' ');
        setServiceLevel(updateServiceLevel);
      } else if (splitValue[0] === 'surveyID') {
        setSurveyID(parseInt(splitValue[1], 10));
        surveyId = parseInt(splitValue[1], 10);
      }
    });

    return { childId, surveyId };
  };

  const retrieveSurveyData = async () => {
    const { childId, surveyId } = getChildAndSurveyId();
    const reqData = {
      surveyID: surveyId,
      childID: childId,
    };

    await getSurveyConfig(reqData);
    const resp = await fetchPssQuestions(reqData);
    setQuestions(resp);
    showQuestionsOrWelcomeScreen(resp);
  };

  useEffect(() => {
    const fetchData = async () => {
      await retrieveSurveyData();
    };
    fetchData();
  }, []);

  return {
    questions,
    currentQuestionNo,
    centreID,
    childID,
    parentID,
    serviceLevel,
    schoolID,
    surveyID,
    showSurveySuccessScreen,
    setShowSurveySuccessScreen,
    showQuestions,
    setShowQuestions,
    showWelcomeScreen,
    surveyCompleted,
    surveyExpired,
  };
};

export default Handler;

import { FETCH_ECDA_REG_DOCUMENTS_TO_UPLOAD } from '../actionTypes';

import {
  SUBMIT_ECDA_REG_FORM,
  REVERT_ECDA_REG_DOCUMENT,
  REMOVE_ECDA_REG_DOCUMENT,
  APPROVE_ECDA_REG_DOCUMENT,
  REJECT_ECDA_REG_DOCUMENT,
  SKIP_ECDA_REG_DOCUMENT,
  GET_ECDA_REG_DOCUMENT_UPLOAD_URL,
  GET_ECDA_REGISTRATION_DOCUMENT_LIST,
  GET_ECDA_REG_TNC_UPLOAD_URL,
} from '../../models/documentModelsECDA';
import { SkApolloRequest } from '../../../utils/apolloUtils';

const dispatchDocumentUploadList = (
  dispatch,
  inProgress = true,
  data = {},
  error = null
) => {
  dispatch({
    type: FETCH_ECDA_REG_DOCUMENTS_TO_UPLOAD,
    key: 'documentsToUpload',
    value: {
      inProgress,
      data,
      error,
    },
  });
};

export const getECDARegUploadDocumentList =
  IDECDARegistration => async dispatch => {
    dispatchDocumentUploadList(dispatch);

    try {
      const response = await SkApolloRequest({
        params: {
          query: GET_ECDA_REGISTRATION_DOCUMENT_LIST,
          variables: { IDECDARegistration },
        },
        type: 'query',
      });

      if (response.success) {
        dispatchDocumentUploadList(dispatch, false, response.data);
      } else {
        dispatchDocumentUploadList(dispatch, false, null, response.error);
      }

      return response;
    } catch (ex) {
      const errorMessage = ex.message.replace(/GraphQL error./, '');
      dispatchDocumentUploadList(dispatch, false, null, ex);
      return { error: errorMessage };
    }
  };

export const getECDARegistrationDocumentUploadURL =
  (IDECDARegistration, file) => async () => {
    try {
      const response = await SkApolloRequest({
        params: {
          mutation: GET_ECDA_REG_DOCUMENT_UPLOAD_URL,
          variables: {
            IDECDARegistration,
            file,
          },
        },
        type: 'mutation',
      });

      return response;
    } catch (ex) {
      const errorMessage = ex.message.replace(/GraphQL error./, '');
      return { error: errorMessage };
    }
  };

export const removeECDARegUploadedDocument =
  (IDECDARegistration, IDDocument, remarks) => async () => {
    try {
      const response = await SkApolloRequest({
        params: {
          mutation: REMOVE_ECDA_REG_DOCUMENT,
          variables: {
            ecdaRegistrationId: IDECDARegistration,
            documentId: IDDocument,
            remarks,
          },
        },
        type: 'mutation',
      });

      return response;
    } catch (ex) {
      const errorMessage = ex.message.replace(/GraphQL error./, '');
      return { error: errorMessage };
    }
  };

export const approveECDARegDocument = async (
  IDECDARegistration,
  IDDocumentType,
  remarks
) => {
  try {
    const response = await SkApolloRequest({
      params: {
        mutation: APPROVE_ECDA_REG_DOCUMENT,
        variables: {
          ecdaRegistrationId: IDECDARegistration,
          documentTypeId: IDDocumentType,
          remarks,
        },
      },
      type: 'mutation',
    });

    return response;
  } catch (ex) {
    const errorMessage = ex.message.replace(/GraphQL error./, '');
    return { error: errorMessage };
  }
};

export const rejectECDARegDocument =
  (IDECDARegistration, IDDocumentType, remarks) => async () => {
    try {
      const response = await SkApolloRequest({
        params: {
          mutation: REJECT_ECDA_REG_DOCUMENT,
          variables: {
            ecdaRegistrationId: IDECDARegistration,
            documentTypeId: IDDocumentType,
            remarks,
          },
        },
        type: 'mutation',
      });

      return response;
    } catch (ex) {
      const errorMessage = ex.message.replace(/GraphQL error./, '');
      return { error: errorMessage };
    }
  };

export const skipECDARegDocument =
  (IDECDARegistration, IDDocumentType, remarks) => async () => {
    try {
      const response = await SkApolloRequest({
        params: {
          mutation: SKIP_ECDA_REG_DOCUMENT,
          variables: {
            ecdaRegistrationId: IDECDARegistration,
            documentTypeId: IDDocumentType,
            remarks,
          },
        },
        type: 'mutation',
      });

      return response;
    } catch (ex) {
      const errorMessage = ex.message.replace(/GraphQL error./, '');
      return { error: errorMessage };
    }
  };

export const revertECDARegDocument =
  (IDECDARegistration, IDDocumentType, remarks) => async () => {
    try {
      const response = await SkApolloRequest({
        params: {
          mutation: REVERT_ECDA_REG_DOCUMENT,
          variables: {
            ecdaRegistrationId: IDECDARegistration,
            documentTypeId: IDDocumentType,
            remarks,
          },
        },
        type: 'mutation',
      });

      return response;
    } catch (ex) {
      const errorMessage = ex.message.replace(/GraphQL error./, '');
      return { error: errorMessage };
    }
  };

export const submitECDARegForm = (IDECDARegistration, info) => async () => {
  try {
    const response = await SkApolloRequest({
      params: {
        mutation: SUBMIT_ECDA_REG_FORM,
        variables: { ecdaRegistrationId: IDECDARegistration, info },
      },
      type: 'mutation',
    });

    return response;
  } catch (ex) {
    const errorMessage = ex.message.replace(/GraphQL error./, '');
    return { error: errorMessage };
  }
};

export const getECDARegistrationTncUploadURL = async (
  IDECDARegistration,
  file
) => {
  try {
    const response = await SkApolloRequest({
      params: {
        mutation: GET_ECDA_REG_TNC_UPLOAD_URL,
        variables: {
          IDECDARegistration,
          file,
        },
      },
      type: 'mutation',
    });

    return response;
  } catch (ex) {
    const errorMessage = ex.message.replace(/GraphQL error./, '');
    return { error: errorMessage };
  }
};

import { get } from 'lodash';
import {
  FETCH_ALL_REG_CHILD_BALANCE_ERROR,
  FETCH_ALL_REG_CHILD_BALANCE_LOADING,
  FETCH_ALL_REG_CHILD_BALANCE_SUCCESS,
  FETCH_ALL_REG_CHILD_REFUNDS_ERROR,
  FETCH_ALL_REG_CHILD_REFUNDS_LOADING,
  FETCH_ALL_REG_CHILD_REFUNDS_SUCCESS,
} from '../actions/actionTypes';

const DEFAULT_STATE = {
  inProgress: false,
  data: null,
  error: null,
};

// eslint-disable-next-line import/prefer-default-export
export const allECDARegChildBalanceSheet = (state = DEFAULT_STATE, action) => {
  switch (action.type) {
    case FETCH_ALL_REG_CHILD_BALANCE_LOADING:
      return {
        ...state,
        ...action.value,
      };
    case FETCH_ALL_REG_CHILD_BALANCE_SUCCESS:
      return {
        ...state,
        ...action.value,
        data: get(action, 'value.data.getRegistrationChildBalanceSheet'),
      };
    case FETCH_ALL_REG_CHILD_BALANCE_ERROR:
      return {
        ...state,
        ...action.value,
      };
    default:
      return state;
  }
};

export const allECDARegChildRefunds = (state = DEFAULT_STATE, action) => {
  switch (action.type) {
    case FETCH_ALL_REG_CHILD_REFUNDS_LOADING:
      return {
        ...state,
        ...action.value,
      };
    case FETCH_ALL_REG_CHILD_REFUNDS_SUCCESS:
      return {
        ...state,
        ...action.value,
        data: get(action, 'value.data.getEcdaRegistrationRefundChildBalance'),
      };
    case FETCH_ALL_REG_CHILD_REFUNDS_ERROR:
      return {
        ...state,
        ...action.value,
      };
    default:
      return state;
  }
};

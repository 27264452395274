import { get } from 'lodash';
import { APPROVE, PUBLISHED, REJECTED } from '../constants';

const classActivityStatusUpdate = (
  classActivityId,
  classActivities = [],
  type
) => {
  if (!classActivityId || get(classActivities.length) === 0) return {};

  const validTypes = [PUBLISHED, APPROVE, REJECTED];
  let data = classActivities;
  if (type && validTypes.includes(type)) {
    data = classActivities.map(item => {
      if (get(item, 'ID') === classActivityId) {
        item.status = type;
      }
      return item;
    });
  } else {
    data = classActivities.filter(item => get(item, 'ID') !== classActivityId);
  }
  return data;
};

export default classActivityStatusUpdate;

import React, { useMemo } from 'react';
import DOMPurify from 'dompurify';
import PropTypes from 'prop-types';
import defaultSanitizeConfig from './config';

const MarkdownRenderer = React.memo(
  ({ source: markdownText, customConfig = null, useCustomConfig = false }) => {
    const sanitizedMarkup = useMemo(() => {
      if (typeof markdownText !== 'string') {
        return null;
      }

      const config = useCustomConfig
        ? { ...defaultSanitizeConfig, ...customConfig }
        : defaultSanitizeConfig;

      const sanitizedHTML = DOMPurify.sanitize(
        `<div>${markdownText}</div>`,
        config
      );

      return { __html: sanitizedHTML };
    }, [markdownText, customConfig, useCustomConfig]);

    return <div dangerouslySetInnerHTML={sanitizedMarkup} />;
  }
);

MarkdownRenderer.propTypes = {
  source: PropTypes.string.isRequired,
  customConfig: PropTypes.object,
  useCustomConfig: PropTypes.bool,
};

MarkdownRenderer.defaultProps = {
  customConfig: null,
  useCustomConfig: false,
};

export default MarkdownRenderer;

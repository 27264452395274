import React, { useState } from 'react';
import { connect } from 'react-redux';
import {
  Grid,
  Button,
  Radio,
  TextField,
  Typography,
  Hidden,
  useMediaQuery,
} from '@material-ui/core';
import CircularProgress from '@material-ui/core/CircularProgress';
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';
import ArrowBackwardIcon from '@material-ui/icons/ArrowBack';
import { useTheme } from '@material-ui/core/styles';
import ReactMarkdown from 'components/common/ReactMarkDown';
import compose from 'recompose/compose';
import { get } from 'lodash';
import { withTranslation } from 'react-i18next';
import { BASE_COLOR, PRIMARY_COLOR } from 'theme/variables';
import SurveySuccessScreen from './SurveySuccessScreen';
import Handler from './Questions.handler';
import useStyles, { BorderLinearProgress } from '../style';
import {
  ImageLazyLoader,
  getPssFormWidthForDifferentScreens,
  getSchoolData,
} from '../../../../utils';
import Text from '../../../common/Text';
import Dialog from '../../../common/Modal';
import {
  PSS_QUESTION_TYPE_11,
  PSS_QUESTION_TYPE_2,
  PSS_QUESTION_TYPE_3,
  PSS_QUESTION_TYPE_5,
  PSS_QUESTION_TYPE_TEXT,
  CHARACTER_LIMIT_5000,
} from '../../../../utils/constants';
import {
  fetchPssQuestions,
  savePssSurveyResponse,
  showSnackBarMessage,
} from '../../../../redux/actions';

const Questions = props => {
  const { t } = props;
  const classes = useStyles();
  const theme = useTheme();
  const isXs = useMediaQuery(theme.breakpoints.down('xs'));
  const [updateResponseLoading, setUpdateResponseLoading] = useState(false);
  //   const [isDisabledButtonClick, setDisabledButtonClick] = useState(false);

  const {
    updateQuestionNumber,
    serviceLevel,
    saveQuestionAnswers,
    surveyAnswers,
    updateSurveyQuestions,
    schoolID,
    showSurveySuccessScreen,
    setShowSurveySuccessScreen,
    childName,
    isTextInputsEmpty,
    disableNextBtn,
    questionLength,
    currentQuestionData,
    isSection,
    filterCurrentSectionData,
    questionData,
    isDisabledButtonClick,
    setDisabledButtonClick,
    hasResponseCount6,
    submitErr,
    setSubmitErr,
    handleReload,
  } = Handler({ ...props, setUpdateResponseLoading, showSnackBarMessage });

  const { schoolLogo, imageStyle } = getSchoolData(schoolID, 'questions');

  const getClassesForNextOrSubmitButton = () => {
    let allClasses = ``;
    if (disableNextBtn || isTextInputsEmpty() || updateResponseLoading) {
      allClasses += `${classes.submitOrNextButtonDisabled} `;
    } else {
      allClasses += `${classes.submitOrNextButton} `;
    }

    if (isXs) {
      allClasses += `${classes.sectionNavButtonMobile}`;
    } else {
      allClasses += `${classes.sectionNavButtonDesktop}`;
    }

    if (
      !disableNextBtn &&
      get(currentQuestionData, 'questionNo') ===
        get(questionLength, 'questionNo', 0)
    ) {
      allClasses += ` ${classes.pulseButton}`;
    }

    return allClasses;
  };

  const classNamesForButton = getClassesForNextOrSubmitButton();

  const renderProgressBar = () => {
    const progressFn = () => {
      const questionNo = get(currentQuestionData, 'questionNo');
      const totalQuestions = get(questionLength, 'questionNo', 0);

      if (totalQuestions > 0) {
        const rawProgress = ((questionNo - 1) * 100) / totalQuestions;
        const increment = 5; // Change this for increments
        const roundedProgress = Math.round(rawProgress / increment) * increment;
        return Math.min(100, roundedProgress); // Ensuring the progress does not exceed 100%
      }
      return 0; // If there are no questions, progress is 0
    };
    const progress = progressFn();

    return (
      <Grid
        container
        direction="column"
        alignItems="center"
        className={`w3-center ${classes.marginBottom35}`}
      >
        <Text
          className={`w3-right ${classes.progressBarText} ${
            progress === 100 ? classes.textComplete : ' '
          }`}
        >
          {progress}% {t('common.completed')}
        </Text>
        <BorderLinearProgress
          variant="determinate"
          valueBuffer={1}
          value={progress}
          className={classes.progressBar}
        />
        <BorderLinearProgress
          variant="determinate"
          valueBuffer={1}
          value={
            (get(currentQuestionData, 'questionNo') * 100) /
            get(questionLength, 'questionNo', 0)
          }
        />
      </Grid>
    );
  };

  // const renderQuestionHeader = value => {
  const renderQuestionHeader = () => {
    return (
      <Grid className="w3-left s7t-pss-qstn-header">
        {isSection && get(currentQuestionData, 'sectionTitle') ? (
          <ReactMarkdown
            source={`${get(currentQuestionData, 'sectionTitle', '')}`}
            escapeHtml={false}
          />
        ) : (
          <ReactMarkdown
            // source={`Question ${value}`}
            source={` `} // Do not display the Question No if there is no section
            escapeHtml={false}
          />
        )}
      </Grid>
    );
  };

  const renderQuestionLabel = (label, checkAlign = true) => {
    return (
      <Grid className={checkAlign ? 'w3-left' : 'w3-center'}>
        <ReactMarkdown
          className="s7t-font-size-16px"
          source={label}
          escapeHtml={false}
        />
      </Grid>
    );
  };

  const CustomDivider = () => (
    <Grid container>
      <Grid item md={12} xs={12}>
        <div className={classes.dashedDivider} />
      </Grid>
    </Grid>
  );

  const RadioButtonLabelsDesktop = () => (
    <Hidden only={['xs', 'sm']}>
      <Grid container justify="space-between" alignItems="flex-start">
        <Grid item md={6} />
        <Grid item md={1} />
        <Grid item md={5}>
          <Grid container justify="space-between">
            <Grid item>
              <Grid direction="column" container>
                <Text>{t('common.stronglyDisagree')}</Text>
                <Text>非常不同意</Text>
              </Grid>
            </Grid>
            <Grid item />
            <Grid item />
            <Grid item />
            <Grid
              item
              className={hasResponseCount6 ? classes.paddingOnlyRight32 : ''}
            >
              <Grid direction="column" container>
                <Text className={classes.textAlignRight}>
                  {t('common.stronglyAgree')}
                </Text>
                <Text className={classes.textAlignRight}>非常同意</Text>
              </Grid>
            </Grid>
            {hasResponseCount6 && <Grid item />}
          </Grid>
        </Grid>
      </Grid>
    </Hidden>
  );

  const CustomProgressBar = ({ idx }) => (
    <Grid container>
      <Grid item md={12} xs={12}>
        {!isSection && renderProgressBar()}
        {idx === 0 && isSection && renderProgressBar()}
      </Grid>
    </Grid>
  );

  const RadioButtonsType11Desktop = ({ data, filterSurveyResponse }) => (
    <Hidden only={['xs', 'sm']}>
      <Grid
        container
        justify="space-between"
        alignItems="center"
        spacing={4}
        className={classes.paddingTopAndBottom16}
      >
        <Grid item>
          {isSection && renderQuestionLabel(get(data, 'question', ''), true)}
        </Grid>

        <Grid item md={12}>
          <Grid container direction="row" justify="space-between">
            <Grid item>
              <Grid direction="column" container>
                <Grid item>
                  <Text>{t('common.notAtAllLikely')}</Text>
                </Grid>

                <Grid item>
                  <Text>不会</Text>
                </Grid>
              </Grid>
            </Grid>

            <Grid item>
              <Grid direction="column" container>
                <Grid item>
                  <Text>{t('common.veryLikely')}</Text>
                </Grid>

                <Grid item>
                  <Text>一定会</Text>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Grid item md={12}>
          <Grid container direction="row" justify="space-between" spacing={1}>
            {get(isSection ? data : currentQuestionData, 'response', []).map(
              (re, pos) => {
                return (
                  <Grid item>
                    <Grid
                      container
                      direction="column"
                      justify="center"
                      alignItems="center"
                    >
                      <Grid item md={12} xs={1}>
                        <Text className={classes.marginTop20}>{re}</Text>
                      </Grid>
                      <Grid item md={12} xs={4}>
                        <Radio
                          checked={
                            get(filterSurveyResponse, '[0].response') === re
                          }
                          value={1}
                          name="all"
                          onChange={() =>
                            saveQuestionAnswers(
                              isSection ? data : currentQuestionData,
                              pos
                            )
                          }
                          color="primary"
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                );
              }
            )}
          </Grid>
        </Grid>
      </Grid>
    </Hidden>
  );

  const RadioButtonsType11Mobile = ({ data, filterSurveyResponse }) => (
    <Hidden only={['md', 'lg', 'xl']}>
      <Grid
        container
        justify="space-between"
        alignItems="center"
        spacing={4}
        className={classes.paddingTopAndBottom16}
      >
        <Grid item xs={12}>
          {isSection && renderQuestionLabel(get(data, 'question', ''), true)}
        </Grid>

        <Grid item>
          <Grid container direction="row" justify="space-between" spacing={1}>
            {get(isSection ? data : currentQuestionData, 'response', []).map(
              (re, pos) => {
                return (
                  <Grid item xs={12}>
                    <Grid
                      container
                      direction="row"
                      justify="flex-start"
                      alignItems="center"
                    >
                      <Grid item md={12} xs={1}>
                        <Text bold>{re}</Text>
                      </Grid>
                      <Grid item md={12} xs={10}>
                        <Grid container direction="row" alignItems="center">
                          <Grid item>
                            <Radio
                              checked={
                                get(filterSurveyResponse, '[0].response') === re
                              }
                              value={1}
                              name="all"
                              onChange={() =>
                                saveQuestionAnswers(
                                  isSection ? data : currentQuestionData,
                                  pos
                                )
                              }
                              color="primary"
                            />
                          </Grid>
                          {pos === 0 && (
                            <Grid item>
                              <Grid direction="row" container>
                                <Grid item>
                                  <Text>{t('common.notAtAllLikely')}</Text>
                                </Grid>

                                <Grid item>
                                  <Text className={classes.marginLeft10}>
                                    不会
                                  </Text>
                                </Grid>
                              </Grid>
                            </Grid>
                          )}
                          {pos === 10 && (
                            <Grid item>
                              <Grid direction="row" container>
                                <Grid item>
                                  <Text>{t('common.veryLikely')}</Text>
                                </Grid>

                                <Grid item>
                                  <Text className={classes.marginLeft10}>
                                    一定会
                                  </Text>
                                </Grid>
                              </Grid>
                            </Grid>
                          )}
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                );
              }
            )}
          </Grid>
        </Grid>
      </Grid>
    </Hidden>
  );

  const RadioButtonsType5Desktop = ({
    data,
    filterSurveyResponse,
    // responseCount,
  }) => (
    <Hidden only={['xs', 'sm']}>
      <Grid item md={6}>
        {isSection && renderQuestionLabel(get(data, 'question', ''), true)}
      </Grid>
      <Grid item md={1} />
      <Grid item md={5} className={classes.paddingExceptLeft16}>
        <Grid
          item
          container
          justify="space-between"
          className={classes.marginTop15}
        >
          {get(isSection ? data : currentQuestionData, 'response', []).map(
            (res, idx) => {
              return (
                <Grid
                  item
                  key={idx}
                  md={2}
                  direction="column"
                  className={classes.flexBasisAuto}
                >
                  <Text className={classes.marginLeft15}>{res}</Text>
                  <br />
                  <Grid className={classes.marginLeft0}>
                    <Radio
                      checked={
                        get(filterSurveyResponse, '[0].response') === res
                      }
                      value={1}
                      name="all"
                      onChange={() =>
                        saveQuestionAnswers(
                          isSection ? data : currentQuestionData,
                          idx
                        )
                      }
                      color="primary"
                    />
                  </Grid>
                </Grid>
              );
            }
          )}
          {hasResponseCount6 &&
            get(isSection ? data : currentQuestionData, 'response', [])
              .length === 5 && (
              <Grid item className={classes.flexBasisAuto} md={2}>
                <Grid className={classes.customhide}>
                  <Radio />
                </Grid>
              </Grid>
            )}
        </Grid>
      </Grid>
    </Hidden>
  );
  const RadioButtonsType5Mobile = ({
    data,
    filterSurveyResponse,
    responseCount,
  }) => (
    <Hidden only={['md', 'lg', 'xl']}>
      <Grid item xs={12}>
        {isSection && renderQuestionLabel(get(data, 'question', ''), true)}
      </Grid>
      <Grid item xs={12} className={classes.marginTopMinus25}>
        <Grid container justify="space-between">
          <Grid item xs={3}>
            <Grid direction="column" container>
              <Text>{t('common.stronglyDisagree')}</Text>
              <Text>非常不同意</Text>
            </Grid>
          </Grid>
          <Grid xs={1} item />
          <Grid xs={1} item />
          <Grid xs={1} item />
          <Grid item xs={3}>
            <Grid direction="column" container>
              <Text className={classes.textAlignRight}>
                {t('common.stronglyAgree')}
              </Text>
              <Text className={classes.textAlignRight}>非常同意</Text>
            </Grid>
          </Grid>
          {responseCount === 6 && <Grid xs={2} item />}
        </Grid>
      </Grid>
      <Grid item xs={12} className={classes.marginTopMinus15}>
        <Grid item xs={12} container justify="space-between">
          {get(isSection ? data : currentQuestionData, 'response', []).map(
            (res, idx) => {
              return (
                <Grid item md={2} xs={2}>
                  <Grid
                    container
                    direction="column"
                    justify="center"
                    alignItems="center"
                  >
                    <Grid item>
                      <Text bold>{res}</Text>
                    </Grid>
                    <Grid item>
                      <Radio
                        checked={
                          get(filterSurveyResponse, '[0].response') === res
                        }
                        value={1}
                        name="all"
                        onChange={() =>
                          saveQuestionAnswers(
                            isSection ? data : currentQuestionData,
                            idx
                          )
                        }
                        color="primary"
                      />
                    </Grid>
                  </Grid>
                </Grid>
              );
            }
          )}
        </Grid>
      </Grid>
    </Hidden>
  );

  const QuestionHeader = ({ idx }) => (
    <Grid container>
      {!isSection && renderQuestionHeader()}
      {idx === 0 && isSection && renderQuestionHeader()}
    </Grid>
  );

  const renderQuestionType11 = (idx, data) => {
    const checkID = isSection ? data?.ID : currentQuestionData?.ID;
    const filterSurveyResponse = surveyAnswers.filter(sl => sl?.ID === checkID);
    const responseCount = get(data, 'response.length', []);
    return (
      <Grid>
        <CustomProgressBar idx={idx} />
        <QuestionHeader idx={idx} />
        {!isSection && renderQuestionLabel(get(data, 'question', ''))}
        {idx === 0 &&
          isSection &&
          renderQuestionLabel(get(data, 'sectionLabel', true))}
        {idx !== 0 && <CustomDivider />}
        {idx === 0 && <RadioButtonLabelsDesktop />}
        {/* For 11 Radio Button Options */}
        {responseCount === 11 && (
          <>
            <RadioButtonsType11Desktop
              data={data}
              filterSurveyResponse={filterSurveyResponse}
            />
            <RadioButtonsType11Mobile
              data={data}
              filterSurveyResponse={filterSurveyResponse}
            />
          </>
        )}
        {/* For Other Types (5 or 6 Radio Buttons) */}
        {responseCount !== 11 && (
          <Grid
            container
            justify="space-between"
            alignItems="center"
            spacing={4}
            className={classes.paddingTopAndBottom16}
          >
            <RadioButtonsType5Desktop
              data={data}
              filterSurveyResponse={filterSurveyResponse}
              // responseCount={responseCount}
            />
            <RadioButtonsType5Mobile
              data={data}
              filterSurveyResponse={filterSurveyResponse}
              responseCount={responseCount}
            />
          </Grid>
        )}
      </Grid>
    );
  };

  // renderQuestionHeader(get(currentQuestionData, 'questionNo'))}
  const renderQuestionType2 = (idx, data) => {
    const checkID = isSection ? data?.ID : currentQuestionData?.ID;
    const filterSurveyResponse = surveyAnswers.filter(sl => sl?.ID === checkID);
    return (
      <Grid container justify="space-between">
        <CustomProgressBar idx={idx} />
        <QuestionHeader idx={idx} />
        {!isSection && renderQuestionLabel(get(data, 'question', ''))}
        {idx === 0 &&
          isSection &&
          renderQuestionLabel(get(data, 'sectionLabel', ''))}

        {idx !== 0 && <CustomDivider />}
        {idx === 0 && <RadioButtonLabelsDesktop />}
        <Grid container className={classes.paddingTopAndBottom16}>
          <Grid item>
            {isSection && renderQuestionLabel(get(data, 'question', ''), true)}
          </Grid>
          <Grid container item direction="column" justify="space-between">
            {get(data, 'response', []).map((sl, index) => {
              return (
                <Grid item>
                  <Radio
                    checked={get(filterSurveyResponse, '[0].response') === sl}
                    value={1}
                    name="all"
                    onChange={() =>
                      saveQuestionAnswers(
                        isSection ? data : currentQuestionData,
                        index
                      )
                    }
                    color="primary"
                  />
                  <Text>{sl}</Text>
                </Grid>
              );
            })}
          </Grid>
        </Grid>
      </Grid>
    );
  };

  const renderQuestionTypeText = (idx, data) => {
    const checkID = isSection ? data?.ID : currentQuestionData?.ID;
    const filterSurveyResponse = surveyAnswers.filter(sl => sl?.ID === checkID);
    return (
      <Grid>
        {!isSection && renderProgressBar()}
        {idx === 0 && isSection && renderProgressBar()}
        {!isSection && renderQuestionHeader()}
        {idx === 0 && isSection && renderQuestionHeader()}
        {!isSection && renderQuestionLabel(get(data, 'question', ''))}
        {idx === 0 &&
          isSection &&
          renderQuestionLabel(get(data, 'sectionLabel', ''))}
        {idx !== 0 && <CustomDivider />}

        <Grid container className={classes.marginTopBottom20}>
          {isSection && renderQuestionLabel(get(data, 'question', ''), true)}
          <Grid item md={12} xs={12}>
            <Typography>{t('common.inputReasonPlaceholder')}</Typography>
          </Grid>
          <Grid item md={12} xs={12}>
            <Grid container xs={12} className={classes.marginTop10}>
              <TextField
                id="outlined-multiline-static"
                key={checkID}
                multiline
                rows={8}
                placeholder="Input"
                variant="outlined"
                fullWidth
                onChange={text => {
                  if (get(text, 'target.value.length') > 0) {
                    setDisabledButtonClick(false);
                  }
                  saveQuestionAnswers(
                    isSection ? data : currentQuestionData,
                    null,
                    'text',
                    get(text, 'target.value', '').replace(
                      /[^\u4E00-\u9FFF\u3400-\u4DBF\uF900-\uFAFFa-zA-Z0-9,.?:;/"'“‘”-（），。？：；\-()&!\s]/gu,
                      ''
                    )
                  );
                }}
                value={get(filterSurveyResponse, '[0].response')}
              />
              <Grid item xs={12} className="s7t-text-align-end">
                <Text
                  className={classes.textAlignRight}
                  style={{
                    color:
                      get(filterSurveyResponse, '[0].response.length', 0) >=
                      5000
                        ? BASE_COLOR.status.error
                        : '#ABABAB',
                    fontSize: 14,
                  }}
                >{`(${get(
                  filterSurveyResponse,
                  '[0].response.length',
                  0
                )}/${CHARACTER_LIMIT_5000})`}</Text>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    );
  };

  const renderQuestion = (data, idx) => {
    if (
      get(data, 'questionType') === PSS_QUESTION_TYPE_2 ||
      get(data, 'questionType') === PSS_QUESTION_TYPE_3
    ) {
      return renderQuestionType2(idx, data);
    }
    if (
      get(data, 'questionType') === PSS_QUESTION_TYPE_11 ||
      get(data, 'questionType') === PSS_QUESTION_TYPE_5
    ) {
      return renderQuestionType11(idx, data);
    }
    if (get(data, 'questionType') === PSS_QUESTION_TYPE_TEXT) {
      return renderQuestionTypeText(idx, data);
    }
  };

  return (
    <Grid container className={classes.surveyContainer}>
      {get(questionData, 'length', 0) > 0 && (
        <Grid item xs={12} className={classes.innerSurveyContainer}>
          <Grid container justify="center">
            <Grid
              item
              xs={12}
              sm={12}
              md={12}
              className="w3-center s7t-padding-bottom-30 s7t-padding-top-30"
            >
              <Grid container direction="row" className="sn2-d-flex">
                <Grid className="" md={12} xs={12}>
                  <ImageLazyLoader
                    alt="school banner for survey"
                    className="s7t-full-height s7t-login-grid-image"
                    src={schoolLogo}
                    style={imageStyle}
                  />
                </Grid>
                {serviceLevel && (
                  <Grid xs={12} className="s7t-pss-header">
                    <Text color="white">
                      {t('common.submittingSurveyFor', { serviceLevel })}
                    </Text>
                  </Grid>
                )}
              </Grid>
            </Grid>
            <Grid
              item
              xs={12}
              md={getPssFormWidthForDifferentScreens()}
              className={
                isXs
                  ? `s7t-pss-welcome-screen-container-mobile`
                  : `s7t-pss-welcome-screen-container`
              }
            >
              {!isSection &&
                !showSurveySuccessScreen &&
                renderQuestion(currentQuestionData)}
              {isSection && !showSurveySuccessScreen
                ? filterCurrentSectionData.map((items, idx) => {
                    return renderQuestion(items, idx);
                  })
                : null}

              {showSurveySuccessScreen && (
                <SurveySuccessScreen t={t} childName={childName} />
              )}
              {!showSurveySuccessScreen && (
                <>
                  <Grid
                    className={`w3-right s7t-margin-top-30 ${classes.marginRight5}`}
                  >
                    {get(currentQuestionData, 'questionNo') !== 1 && (
                      <Button
                        className={
                          isXs
                            ? `${classes.previousButton} ${classes.sectionNavButtonMobile}`
                            : `${classes.previousButton} ${classes.sectionNavButtonDesktop}`
                        }
                        variant="outlined"
                        onClick={() => updateQuestionNumber('previous')}
                        startIcon={
                          <ArrowBackwardIcon style={{ color: PRIMARY_COLOR }} />
                        }
                      >
                        <Text className="s7t-pss-primary-btn" color="primary">
                          {t('common.previous')}
                        </Text>
                      </Button>
                    )}
                    {get(currentQuestionData, 'questionNo') ===
                    get(questionLength, 'questionNo', 0) ? (
                      <Button
                        className={classNamesForButton}
                        onClick={async () => {
                          if (
                            disableNextBtn ||
                            isTextInputsEmpty() ||
                            updateResponseLoading
                          ) {
                            setDisabledButtonClick(true);
                          } else {
                            setDisabledButtonClick(false);
                            setUpdateResponseLoading(true);
                            const res = await updateSurveyQuestions(
                              'normal',
                              true
                            );
                            const errorResp = res && (res.error || res.errors);
                            if (errorResp) {
                              setSubmitErr(true);
                            } else {
                              setShowSurveySuccessScreen(true);
                            }
                          }
                        }}
                        endIcon={
                          !updateResponseLoading && (
                            <ArrowForwardIcon style={{ color: 'white' }} />
                          )
                        }
                      >
                        {updateResponseLoading ? (
                          <CircularProgress
                            size={24}
                            style={{ color: 'white' }}
                          />
                        ) : (
                          <Text className="s7t-pss-primary-btn">
                            {t('common.submit')}
                          </Text>
                        )}
                      </Button>
                    ) : (
                      <Button
                        className={classNamesForButton}
                        onClick={async () => {
                          if (
                            disableNextBtn ||
                            isTextInputsEmpty() ||
                            updateResponseLoading
                          ) {
                            setDisabledButtonClick(true);
                          } else {
                            setDisabledButtonClick(false);
                            setUpdateResponseLoading(true);
                            await updateQuestionNumber('next');
                          }
                        }}
                        endIcon={
                          !updateResponseLoading && (
                            <ArrowForwardIcon style={{ color: 'white' }} />
                          )
                        }
                      >
                        {updateResponseLoading ? (
                          <CircularProgress
                            size={24}
                            style={{ color: 'white' }}
                          />
                        ) : (
                          <Text className="s7t-pss-primary-btn">
                            {t('common.next')}
                          </Text>
                        )}
                      </Button>
                    )}
                  </Grid>
                  <Grid container className="w3-center" justify="center">
                    {isDisabledButtonClick &&
                      (disableNextBtn || isTextInputsEmpty()) && (
                        <Grid className={`w3-center ${classes.marginTop20}`}>
                          <ReactMarkdown
                            className={classes.errorText}
                            source={t('common.pssQuestionError')}
                            escapeHtml={false}
                          />
                        </Grid>
                      )}
                  </Grid>
                  <Dialog
                    data-testid="dialog-id4"
                    showClose
                    maxWidth="sm"
                    isOpen={submitErr}
                    dialogTitle={t('common.pssNullErrorTitle')}
                    dialogContent={t('common.pssNullErrorDescription')}
                    onPrimaryClick={handleReload}
                    onClose={handleReload}
                    contentStyle="w3-padding-16"
                    hideSecondaryBtn
                    buttonPrimaryVariant="text"
                    buttonSize="small"
                    primaryButtonStyle={{
                      fontWeight: 'bold',
                    }}
                    buttonPrimaryName={t('common.okay2')}
                    customJustifyContent="flex-end"
                  />
                </>
              )}
            </Grid>
          </Grid>
        </Grid>
      )}
    </Grid>
  );
};

const mapStateToProps = state => ({
  pssTimer: get(state, 'schoolConfig.list.asObject.pss_save_timer', 300000),
});

const mapDispatchToProps = {
  fetchPssQuestions,
  savePssSurveyResponse,
};

export default compose(withTranslation())(
  connect(mapStateToProps, mapDispatchToProps)(Questions)
);

import { get } from 'lodash';
import axios from 'axios';
import LocalStore from '../localStorage';

const handleError = error => Promise.reject(error);

const defaultConfigs = {
  // use baseURL as default endpoint of axios instance, will update when MS supports for API Gateway
  baseURL: process.env.REACT_APP_MS_BROADCAST_URL,
  headers: {
    'Content-Type': 'application/json',
    Accept: 'application/json',
  },
  timeout: 15000,
  interceptors: {
    request: [
      request => {
        const token = LocalStore.getValue('userToken');
        if (token) request.headers.Authorization = `Bearer ${token}`;
        return request;
      },
      handleError,
    ],
    response: [response => response, handleError],
  },
};

export const AxiosHelper = configs => {
  const options = { ...defaultConfigs, ...(configs || {}) };
  const _instance = axios.create(options);

  if (get(options, 'interceptors.request')) {
    _instance.interceptors.request.use(...get(options, 'interceptors.request'));
  }

  if (get(options, 'interceptors.response')) {
    _instance.interceptors.response.use(
      ...get(options, 'interceptors.response')
    );
  }

  const _request = async axiosOpts => {
    try {
      const source = axios.CancelToken.source();
      const response = await _instance.request(
        Object.assign(
          {
            responseType: 'json',
            ...axiosOpts,
            cancelToken: source.token,
          },
          !get(axiosOpts, 'method') && {
            method: 'get',
          }
        )
      );

      // Support for download media & blob, arraybuffer
      if (
        axiosOpts.responseType &&
        ['blob', 'arraybuffer'].includes(axiosOpts.responseType)
      ) {
        const fileName = get(
          (get(response.headers, 'content-disposition') || '').split(';'),
          '[1]',
          ''
        ).replace('filename=', '');
        return Promise.resolve({
          data: response?.data,
          fileName,
        });
      }

      return Promise.resolve(get(response, 'data'));
    } catch (error) {
      if (get(error, 'response')) {
        return Promise.reject(get(error, 'response.data'));
      }
      return Promise.reject(error);
    }
  };

  return {
    _instance,
    request: _request,
    get: (url, params = {}) => _instance.get(url, { params }),
    post: (url, body, options = {}) => _instance.post(url, body, options),
    put: (url, body, options = {}) => _instance.put(url, body, options),
    delete: (url, body) => _instance.delete(url, body),
  };
};

const AxiosRequest = AxiosHelper();
export default AxiosRequest;

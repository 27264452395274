const defaultSanitizeConfig = {
  ALLOWED_TAGS: [
    'html',
    'head',
    'body',
    'div',
    'span',
    'p',
    'h1',
    'h2',
    'h3',
    'h4',
    'h5',
    'h6',
    'ul',
    'ol',
    'li',
    'a',
    'strong',
    'em',
    'br',
    'blockquote',
    'pre',
    'code',
    'img',
    'video',
    'audio',
    'source',
    'iframe',
    'embed',
    'object',
    'param',
    'form',
    'input',
    'textarea',
    'button',
    'select',
    'option',
    'label',
    'fieldset',
    'legend',
    'table',
    'thead',
    'tbody',
    'tfoot',
    'tr',
    'td',
    'th',
    'nav',
    'header',
    'footer',
    'section',
    'article',
    'aside',
    'details',
    'summary',
    'u',
  ],
  ALLOWED_ATTR: [
    'id',
    'class',
    'style',
    'title',
    'data-*',
    'lang',
    'dir',
    'href',
    'target',
    'rel',
    'download',
    'src',
    'alt',
    'width',
    'height',
    'action',
    'method',
    'name',
    'value',
    'placeholder',
    'type',
    'checked',
    'disabled',
    'readonly',
    'required',
    'controls',
    'autoplay',
    'loop',
    'muted',
    'poster',
    'frameborder',
    'allow',
    'allowfullscreen',
    'onclick',
    'onchange',
    'onmouseover',
    'onmouseout',
    'onkeydown',
    'onkeyup',
    'onfocus',
    'onblur',
  ],
  ADD_ATTR: ['allowfullscreen'],
  ALLOWED_URI_REGEXP:
    /^(?:(?:https?|mailto|tel):|[^a-z+.:]|[a-z+.-]+(?:[^a-z+.:]|$))/i,
};

export default defaultSanitizeConfig;

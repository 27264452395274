import { LinearProgress, withStyles } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { PRIMARY_COLOR, BASE_COLOR } from 'theme/variables';

const useStyles = makeStyles({
  marginBottom10: {
    marginBottom: '10px',
  },
  errorOutlineIcon: {
    color: 'red',
    height: 66,
    width: 66,
  },
  checkCircleIcon: {
    color: '#12B886',
  },
  nextButton: {
    backgroundColor: PRIMARY_COLOR,
    textTransform: 'none',
  },
  submitOrNextButton: {
    backgroundColor: PRIMARY_COLOR,
    textTransform: 'none',
    '&:hover': {
      backgroundColor: PRIMARY_COLOR,
      textTransform: 'none',
    },
  },
  submitOrNextButtonDisabled: {
    backgroundColor: '#ABABAB',
    textTransform: 'none',
    '&:hover': {
      backgroundColor: '#ABABAB',
      textTransform: 'none',
    },
  },
  previousButton: {
    borderColor: PRIMARY_COLOR,
    textTransform: 'none',
    marginRight: 10,
  },
  sectionNavButtonDesktop: {
    width: '140px',
  },
  sectionNavButtonMobile: {
    width: '125px',
  },
  surveyContainer: {
    minHeight: '100vh',
    justifyContent: 'center',
    display: 'flex',
    backgroundColor: PRIMARY_COLOR,
  },
  innerSurveyContainer: {
    justifyContent: 'center',
    marginLeft: 5,
    marginRight: 5,
  },
  welcomeScreenContainer: {
    justifyContent: 'center',
    display: 'flex',
    backgroundColor: PRIMARY_COLOR,
  },
  welcomeScreenInnerContainer: {
    justifyContent: 'center',
    marginLeft: 5,
    marginRight: 5,
    marginBottom: 20,
  },
  dashedDivider: {
    borderBottom: `1px dashed ${BASE_COLOR.neutral.mediumGrey}`,
  },
  marginBottom35: { marginBottom: 35 },
  marginLeft10: { marginLeft: '10px' },
  marginTop15: { marginTop: 15 },
  marginTop20: { marginTop: 20 },
  marginTop10: { marginTop: 10 },
  marginLeft0: {
    marginLeft: 0,
  },
  marginRight5: { marginRight: 5 },
  marginLeft15: { marginLeft: 15 },
  marginTopMinus15: { marginTop: -15 },
  marginTopMinus25: { marginTop: -25 },
  marginTopBottom20: { margin: '20px 0px' },
  progressBarText: {
    color: BASE_COLOR.neutral.grey,
    fontWeight: 700,
    marginBottom: '8px',
  },
  textAlignRight: { textAlign: 'right' },
  paddingTopAndBottom16: { padding: '16px 0px' },
  paddingExceptLeft16: { padding: '16px 16px 16px 0px !important' },
  paddingOnlyRight32: { padding: '0px 32px 0px 0px !important' },
  errorText: { color: 'red', overflowWrap: 'break-word' },
  progressBar: { width: '100%' },
  textComplete: { color: '#12B886' },
  flexBasisAuto: { flexBasis: 'auto' },
  customhide: { marginLeft: 0, visibility: 'hidden' },
  // Glowing button styles
  pulseButton: {
    padding: '10px 20px',
    height: '40px',
    color: '#fff',
    backgroundColor: BASE_COLOR.neutral.green,
    border: 'none',
    borderRadius: '5px',
    cursor: 'pointer',
    animation: '$pulse 2s infinite',
  },
  '@keyframes pulse': {
    '0%': {
      transform: 'scale(1)',
      boxShadow: `0 0 0 0 ${BASE_COLOR.neutral.green}`,
    },
    '70%': {
      transform: 'scale(1.05)',
      boxShadow: '0 0 0 15px rgba(234, 76, 137, 0)',
    },
    '100%': {
      transform: 'scale(1)',
      boxShadow: '0 0 0 0 rgba(234, 76, 137, 0)',
    },
  },
});

export const BorderLinearProgress = withStyles(() => ({
  root: {
    height: 5,
  },
  colorPrimary: {
    backgroundColor: '#CFCFCF',
  },
  bar: {
    backgroundColor: '#12B886',
  },
}))(LinearProgress);

export default useStyles;

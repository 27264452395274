const URLs =
  parseInt(process.env.REACT_APP_SCHOOL_ID, 10) === 1
    ? {
        appSchemeURL: process.env.REACT_APP_MFS_PARENT_APP_SCHEME_URL,
        android: process.env.REACT_APP_MFS_PARENT_APP_ANDROID_URL,
        ios: process.env.REACT_APP_MFS_PARENT_APP_IOS_URL,
        huawei: process.env.REACT_APP_MFS_PARENT_APP_HUAWEI_URL,
      }
    : {
        appSchemeURL: process.env.REACT_APP_LSH_PARENT_APP_SCHEME_URL,
        android: process.env.REACT_APP_LSH_PARENT_APP_ANDROID_URL,
        ios: process.env.REACT_APP_LSH_PARENT_APP_IOS_URL,
        huawei: process.env.REACT_APP_LSH_PARENT_APP_HUAWEI_URL,
      };

export default URLs;

/* eslint-disable no-console, camelcase */
/* eslint-disable import/no-cycle */
import { useSelector } from 'react-redux';
import moment from 'moment';
import {
  get,
  isEmpty,
  isNumber,
  last,
  has,
  isArray,
  uniq,
  camelCase,
  set,
  sortBy,
} from 'lodash';
import { PREPARE_PORTFOLIOS } from 'routes/constants';
import {
  MS_PHASE_2B_CLASS_ACTIVITY_ENPOINT,
  classOpsService,
} from 'utils/msEndpointConstants';
import DatadogHandler from 'utils/datadog';
import {
  ENROLLED,
  SNACKBAR_HIDE_TIMEOUT,
  APPROVED,
  YEAR_FORMAT,
  SAVE_DATE_FORMAT,
  DRAFT,
  DEFAULT_ERROR,
} from '../../../../utils/constants';
import {
  showSnackBarMessage,
  hideSnackBarMessage,
} from '../../../../redux/actions';
import {
  transferUnauthorizedErrorMessage,
  replaceUrlParam,
  constructFullName,
  retrieveListOrObject,
  retrieveDataMapper,
  retrieveObjectMapper,
  retrieveSortForMS,
} from '../../../../utils';
import {
  centreSerivce,
  childService,
  classService,
  schoolService,
  userService,
} from '../../../../utils/msEndpointConstants';

const {
  DELETE,
  SAVE_CHILD_PORTFOLIO,
  UPDATE_PORTFOLIO_STATUS,
  GET_PORTFOLIO_PHOTO_UPLOAD_URL,
  GET_CHILD_AREA_OF_INTEREST_AND_RECOMMENDATION,
  GET_PORTFOLIO_TERM_BY_YEAR,
  GET_PORTFOLIO_BY_ID,
  GET_PORTFOLIO_CLASS_TEACHERS,
  GET_TERM_BY_IDS,
  GET_PORTFOLIO_CHILD_LEARNING_GOAL_EVALUATIONS,
} = MS_PHASE_2B_CLASS_ACTIVITY_ENPOINT.PORTFOLIO;

const {
  PORTFOLIO: {
    GET_CHILD_PORTFOLIO,
    GET_PORTFOLIO_CLASS_ACTIVITIES,
    GET_PORTFOLIO_CLASS_ACTIVITY_BY_ID,
    GET_DOMAINS_BY_DOMAIN_IDS,
    BULK_PUBLISH_PORTFOLIOS,
    GET_LIST_DOMAIN,
    GET_PORTFOLIO_UPLOAD_URL,
  },
  USER: { GET_BY_IDS },
  CLASS: {
    GET_CLASS_BY_IDS,
    GET_CLASS_TEACHERS_BY_CLASS_IDS,
    GET_LEARNING_GOALS_BY_IDS,
    GET_LEARNING_GOALS_BY_CLASS,
  },
  SCHOOL: { GET_LEVEL_BY_IDS },
  CHILD: {
    GET_CHILD_BY_IDS,
    GET_CHILD_CURRENT_CLASS_BY_IDS,
    GET_LEVEL_BY_CHILD_IDS,
    GET_CLOSED_LEVEL_BY_CHILD_IDS,
    GET_CHILD_CLASSES_BY_CHILD_IDS,
    GET_CHILD_CLASSES_AT_BY_CHILD_IDS,
  },
  CENTRE: {
    GET_CENTRE_BY_IDS,
    GET_PROGRAMS_BY_IDS,
    GET_CHILD_HISTORICAL_CENTRE_CLASS,
  },
  CHECKLIST: { GET_EVALUATION_LABELS },
} = MS_PHASE_2B_CLASS_ACTIVITY_ENPOINT;

export const getSelector = (targetField, defaultVal) =>
  useSelector(state => get(state, targetField, defaultVal));

export const variableBody = (params, currentYear) => {
  const {
    fkCentre,
    fkClass,
    fkLevel,
    fkTerm,
    year,
    fkProgram,
    status,
    page,
    perPage,
    childIds = [],
    activeSort,
    childStatus = ENROLLED,
  } = params;

  const childIdsTypeParams =
    typeof childIds === 'string'
      ? childIds.split(',').filter(Boolean)
      : childIds;

  const reqData = {
    variables: {
      pagination: {
        page,
        perPage,
        sort: ['-updatedAt'],
        ...(activeSort && {
          sort: [get(activeSort.split('--'), '[0]')],
        }),
      },
      ChildID: !isEmpty(childIds)
        ? childIdsTypeParams.map(id => parseInt(id, 10))
        : [],
      childStatus,
      time:
        has(params, 'year') && isNumber(get(params, 'year'))
          ? moment([get(params, 'year')], YEAR_FORMAT)
              .endOf('year')
              .format(SAVE_DATE_FORMAT)
          : moment(currentYear, YEAR_FORMAT)
              .endOf('year')
              .format(SAVE_DATE_FORMAT),
      customFilter: {
        ...(fkCentre && { fkCentre: [fkCentre] }),
        ...(fkClass && { fkClass: [fkClass] }),
        ...(fkLevel && { fkLevel: [fkLevel] }),
        ...(fkProgram && { fkProgram: [fkProgram] }),
        ...(status && { status: [status] }),
        ...(fkTerm && { fkTerm: [fkTerm] }),
        ...(year && { year: [year] }),
      },
    },
  };

  return reqData;
};

export const getPortfoliosWithFilter = (
  urlParams = {},
  getPortfolios = () => {}
) => {
  const { fkCentre } = urlParams;
  if (!fkCentre) return;
  const currentYear = moment().year();
  const filterBody = variableBody(urlParams, currentYear);
  return getPortfolios(filterBody);
};

export const handleChange = (
  e = {},
  changeParam = () => {},
  urlParams = {},
  getPortfolios = () => {}
) => {
  const { name, value } = get(e, 'target') || {};

  changeParam(
    {
      [name]: value ?? '',
      page: 1,
      perPage: 10,
      ...(name === 'fkLevel' && {
        fkClass: '',
        childIds: '',
      }),
      ...(name === 'fkClass' && {
        childIds: '',
      }),
      ...(name === 'year' && {
        fkTerm: '',
      }),
      ...(name === 'childStatus' && {
        fkLevel: '',
        fkClass: '',
        fkProgram: '',
        childIds: '',
      }),
    },
    (params = {}) =>
      getPortfoliosWithFilter(
        {
          ...urlParams,
          ...params,
          [name]: value,
        },
        getPortfolios
      )
  );
};

export const handleChangeChildNameFilter = (
  value = [],
  changeParam = () => {},
  urlParams = {},
  getPortfolios = () => {}
) => {
  const childIds = value.map(v => parseInt(get(v, 'value'), 10)) || [];
  changeParam(
    {
      childIds,
      page: 1,
      perPage: 10,
    },
    () =>
      getPortfoliosWithFilter(
        {
          ...urlParams,
          childIds,
          page: 1,
          perPage: 10,
        },
        getPortfolios
      )
  );
};

export const handlePageChanged = (
  page = 1,
  perPage = 10,
  changeParam = () => {},
  urlParams = {},
  getPortfolios = () => {}
) => {
  changeParam(
    {
      page,
      perPage,
    },
    () =>
      getPortfoliosWithFilter(
        {
          ...urlParams,
          page,
          perPage,
        },
        getPortfolios
      )
  );
};

export const onClear = (
  changeParam = () => {},
  urlParams = {},
  getPortfolios = () => {}
) =>
  changeParam(
    {
      ...urlParams,
      fkCentre: '',
      childStatus: ENROLLED,
      fkLevel: '',
      fkClass: '',
      status: '',
      fkProgram: '',
      year: '',
      fkTerm: '',
      page: 1,
      perPage: 10,
      activeSort: '-updatedAt',
    },
    () =>
      getPortfoliosWithFilter(
        {
          ...urlParams,
          fkCentre: '',
          childStatus: ENROLLED,
          fkLevel: '',
          fkClass: '',
          status: '',
          fkProgram: '',
          year: '',
          fkTerm: '',
          page: 1,
          perPage: 10,
          activeSort: '-updatedAt',
        },
        getPortfolios
      )
  );

export const fetchHistoricalChildData = async (
  childId = null,
  getHistoricalChildCenterData = () => {},
  fkCentre = null
) => {
  try {
    await getHistoricalChildCenterData({
      variables: {
        childId: parseInt(childId, 10),
        centreId: parseInt(fkCentre, 10),
      },
    });
  } catch (e) {
    console.log('e', e);
  }
};

export const onCancelBulkPublishSelection = (
  setAllowSelection = () => {},
  changeParam = () => {},
  selectedIds = [],
  setSelectedIds = () => {},
  urlParams = {},
  getPortfolios = () => {}
) => {
  setAllowSelection(false);
  changeParam(
    {
      status: '',
      page: 1,
    },
    () =>
      getPortfoliosWithFilter(
        {
          ...urlParams,
          status: '',
          page: 1,
        },
        getPortfolios
      )
  );
  if (get(selectedIds, 'length', 0) > 0) setSelectedIds([]);
};

export const bulkPublishPortfolios = async ({
  selectedIds = [],
  bulkPublish = () => {},
  dispatch = () => {},
  t = () => {},
  bulkPublishhandlers = {},
  setModalConfirm = () => {},
}) => {
  const {
    setAllowSelection = () => {},
    changeParam = () => {},
    setSelectedIds = () => {},
    urlParams = {},
    getPortfolios = () => {},
  } = bulkPublishhandlers;

  if (get(selectedIds, 'length', 0) > 0) {
    const result = await bulkPublish({
      variables: {
        id: selectedIds,
      },
    });
    if (get(result, 'errors')) {
      dispatch(
        showSnackBarMessage(
          transferUnauthorizedErrorMessage(
            get(result, 'errors', ''),
            t('portfolioManagement.unauthorizedErrorMessage')
          ),
          'error'
        )
      );
    } else {
      dispatch(
        showSnackBarMessage(
          t('portfolioManagement.bulkPublishPortfoliosSuccess', {
            count: get(selectedIds, 'length', 0),
            portfolioType:
              get(selectedIds, 'length', 0) > 1
                ? t('portfolioManagement.portfolios', {
                    defaultValue: 'Portfolios',
                  }).toLowerCase()
                : t('portfolioManagement.portfolio', {
                    defaultValue: 'Portfolio',
                  }).toLowerCase(),
            parentType:
              get(selectedIds, 'length', 0) > 1
                ? t('portfolioManagement.parents', {
                    defaultValue: 'Parents',
                  }).toLowerCase()
                : t('portfolioManagement.parent', {
                    defaultValue: 'Parent',
                  }).toLowerCase(),
            supportingText:
              get(selectedIds, 'length', 0) > 1
                ? t('portfolioManagement.are', {
                    defaultValue: 'are',
                  })
                : t('portfolioManagement.is', {
                    defaultValue: 'is',
                  }),
          }),
          'success'
        )
      );
      onCancelBulkPublishSelection(
        setAllowSelection,
        changeParam,
        selectedIds,
        setSelectedIds,
        urlParams,
        getPortfolios
      );
    }
    setTimeout(() => {
      dispatch(hideSnackBarMessage());
    }, SNACKBAR_HIDE_TIMEOUT);
    setModalConfirm(false);
  }
};

export const navigatesToPortfolioCreatePage = ({
  portfolio = {},
  isEdit = false,
  historicalData = [],
  basicInfo = {},
  location = {},
  history = {},
}) => {
  const {
    childStatus = ENROLLED,
    enrolledChildSearchData = {},
    withdrawnedChildSearchData = {},
    selectedChild = null,
  } = basicInfo;
  const { pathname = '', search = '' } = location;
  const latestHistoricalChildLevel = last(historicalData, {});
  const latestHistoricalClass = last(
    get(latestHistoricalChildLevel, 'Classes', [])
  );
  let currentChild = null;
  const children =
    childStatus === ENROLLED
      ? get(
          enrolledChildSearchData,
          'getAllClassInfo.data[0].currentChildClasses.data',
          []
        )
      : get(withdrawnedChildSearchData, 'getPastChildrenData.data', []);
  currentChild = children.find(
    eachChild => get(eachChild, 'child.ID') === selectedChild
  );
  if (isEmpty(currentChild) || isEdit) {
    currentChild = get(portfolio, 'child', {});
  }
  const selectedChildren =
    childStatus === ENROLLED ? currentChild : get(currentChild, 'child', null);

  const currentChildren = isEdit ? get(portfolio, 'child') : selectedChildren;
  const hasSelectedClassAndLevel =
    get(portfolio, 'fkLevel', null) &&
    get(portfolio, 'fkClass', null) &&
    isEdit;

  const uriComponent = encodeURIComponent(`${pathname}${search}`);

  const childParam = has(currentChildren, 'ID')
    ? get(currentChildren, 'ID')
    : get(currentChildren, 'child.ID');

  const params = {
    childId: isNumber(get(portfolio, 'child.ID'))
      ? parseInt(get(portfolio, 'child.ID'), 10)
      : parseInt(childParam, 10),
    levelId: hasSelectedClassAndLevel
      ? parseInt(get(portfolio, 'fkLevel'), 10)
      : parseInt(get(latestHistoricalChildLevel, 'ID'), 10),
    classId: hasSelectedClassAndLevel
      ? parseInt(get(portfolio, 'fkClass'), 10)
      : parseInt(get(latestHistoricalClass, 'ID'), 10),
  };

  if (isEdit) params.id = get(portfolio, 'ID');

  const urlEndpoint = `${replaceUrlParam(
    PREPARE_PORTFOLIOS,
    params
  )}?urlCallback=${uriComponent}`;

  const childFirstName = isNumber(get(currentChildren, 'ID'))
    ? get(currentChildren, 'firstname')
    : get(currentChildren, 'child.firstname');
  const childLastName = isNumber(get(currentChildren, 'ID'))
    ? get(currentChildren, 'lastname')
    : get(currentChildren, 'child.lastname');

  history.push(urlEndpoint, {
    childName: !isEmpty(get(portfolio, 'child'))
      ? constructFullName(
          get(portfolio, 'child.firstname'),
          get(portfolio, 'child.lastname')
        )
      : constructFullName(childFirstName, childLastName),
    childStatus,
    historicalData,
  });
};

export const handleAutoCompleteChange = (
  e = {},
  value = {},
  changeParam = () => {}
) => {
  e.preventDefault();
  changeParam({
    fkClass: '',
    childIds: '',
    fkCentre: get(value, 'label', ''),
    page: 1,
    perPage: 10,
  });
};

export const enableBulkPublish = (
  setAllowSelection = () => {},
  status = DRAFT,
  changeParam = () => {},
  urlParams = {},
  getPortfolios = () => {}
) => {
  setAllowSelection(true);

  if (status !== APPROVED.toLowerCase()) {
    changeParam(
      {
        status: APPROVED.toLowerCase(),
        page: 1,
        perPage: 10,
      },
      () =>
        getPortfoliosWithFilter(
          {
            ...urlParams,
            status: APPROVED.toLowerCase(),
            page: 1,
            perPage: 10,
          },
          getPortfolios
        )
    );
  }
};

export const closeAddPortfolioModal = (
  setChildSelectionModal = () => {},
  setSelectedChild = () => {}
) => {
  setChildSelectionModal(false);
  setSelectedChild(null);
};

export const getFilterSource = (
  source = [],
  fieldMaps = ['ID', 'ID', 'label'],
  isClass = false
) => {
  return isArray(source)
    ? source.map(item => ({
        ID: item[fieldMaps[0]],
        label: item[fieldMaps[1]],
        description: isClass
          ? `${item[fieldMaps[2]]} (${get(item, 'centre.label', '')})`
          : item[fieldMaps[2]],
      }))
    : [];
};

export const msDeletePortfolio = async (_, reqBody = {}) => {
  const portfolioId = get(reqBody, 'portfolioID');
  try {
    const res = await classOpsService().delete(DELETE(portfolioId));
    return res;
  } catch (ex) {
    DatadogHandler.addError(ex);
    DatadogHandler.sendLog(ex, {}, 'error');
    return {
      data: undefined,
      errors: [get(ex, 'message', DEFAULT_ERROR)],
    };
  }
};

export const msSavePortfolio = async (_, reqBody = {}) => {
  try {
    const body = {
      class_teacher_ids: reqBody?.classTeacherIDs,
      portfolio_dto: {
        approver_note: reqBody?.dto?.approverNote,
        behaviour_of_child: reqBody?.dto?.behaviourOfChild,
        child_interests_and_strengths: reqBody?.dto?.childInterestsAndStrengths,
        cover_description: reqBody?.dto?.coverDescription,
        cover_image: reqBody?.dto?.coverImage,
        fk_child: reqBody?.dto?.fkChild,
        fk_child_evaluation: reqBody?.dto?.fkChildEvaluation,
        fk_class: reqBody?.dto?.fkClass,
        fk_level: reqBody?.dto?.fkLevel,
        fk_term: +reqBody?.dto?.fkTerm,
        my_physical_self: reqBody?.dto?.myPhysicalSelf,
        my_psyco_social_self: reqBody?.dto?.myPsycoSocialSelf,
        my_thinking_and_communication_self:
          reqBody?.dto?.myThinkingAndCommunicationSelf,
        portfolio_class_activity: reqBody?.dto?.portfolioClassActivityDto?.map(
          d => ({
            fk_domain: d?.fkDomain,
            fk_class_activity: d?.fkClassActivity,
          })
        ),
        portfolio_url: reqBody?.dto?.portfolioURL,
        post_conference_note: reqBody?.dto?.postConferenceNote,
        post_conference_note_plans: reqBody?.dto?.postConferenceNotePlans,
        status: reqBody?.dto?.status,
        summary: reqBody?.dto?.summary,
        title: reqBody?.dto?.title,
        area_of_recommendation: reqBody?.dto?.areaOfRecommendation,
        summary_last_sync_time: reqBody?.dto?.summaryLastSyncTime,
        area_of_interest: reqBody?.dto?.areaOfInterest,
      },
      ...(reqBody?.portfolioID && { portfolio_id: reqBody?.portfolioID }),
    };
    const res = await classOpsService().post(SAVE_CHILD_PORTFOLIO, body);
    return {
      data: {
        saveChildPortfolio: {
          ID: get(res, 'data.data.id'),
        },
      },
    };
  } catch (ex) {
    DatadogHandler.addError(ex);
    DatadogHandler.sendLog(ex, {}, 'error');
    return {
      data: undefined,
      errors: [
        get(ex, 'response.data.error') || get(ex, 'message', DEFAULT_ERROR),
      ],
    };
  }
};

export const msChangePortfolioStatus = async (_, reqBody = {}) => {
  try {
    const body = {
      id: +get(reqBody, 'id'),
      rejected_note: get(reqBody, 'rejectedNote', ''),
      status: get(reqBody, 'status'),
    };
    const res = await classOpsService().put(UPDATE_PORTFOLIO_STATUS, body);
    return res;
  } catch (ex) {
    DatadogHandler.addError(ex);
    DatadogHandler.sendLog(ex, {}, 'error');
    return {
      data: undefined,
      errors: [get(ex, 'message', DEFAULT_ERROR)],
    };
  }
};

export const msGetPortfolioPhotoUploadURL = async (_, reqBody = {}) => {
  try {
    const body = {
      child_id: reqBody?.IDChild,
      file: {
        mime_type: reqBody?.file?.mimetype,
        file_size: reqBody?.file?.filesize,
        file_name: reqBody?.file?.filename,
      },
    };
    const res = await classOpsService().post(
      GET_PORTFOLIO_PHOTO_UPLOAD_URL,
      body
    );
    return {
      data: {
        getPortfolioPhotoUploadURL: get(res, 'data', {}),
      },
    };
  } catch (ex) {
    DatadogHandler.addError(ex);
    DatadogHandler.sendLog(ex, {}, 'error');
    return {
      data: undefined,
      errors: [get(ex, 'message', DEFAULT_ERROR)],
    };
  }
};

export const msSyncChildAOIAndRecommendation = async (_, reqBody = {}) => {
  try {
    const body = {
      child_id: +get(reqBody, 'IDChild'),
      level_id: +get(reqBody, 'FkLevel'),
      term_id: +get(reqBody, 'FkTerm'),
    };
    const res = await classOpsService().post(
      GET_CHILD_AREA_OF_INTEREST_AND_RECOMMENDATION,
      body
    );
    return {
      data: {
        getChildAreaOfInterestAndRecommendation: {
          AreaOfInterest: get(res, 'data.area_of_interest', ''),
          AreaOfRecommendation: get(res, 'data.area_of_recommendation', ''),
        },
      },
    };
  } catch (ex) {
    DatadogHandler.addError(ex);
    DatadogHandler.sendLog(ex, {}, 'error');
    return {
      data: undefined,
      errors: [get(ex, 'message', DEFAULT_ERROR)],
    };
  }
};

export const msGetPortfolioTermByYear = async (_, reqBody = {}) => {
  try {
    const body = {
      year_ids: get(reqBody, 'year') || [],
    };
    const res = await classOpsService().post(GET_PORTFOLIO_TERM_BY_YEAR, body);

    return {
      getPortfolioTermByYear: (get(res, 'data.data') || []).map(
        ({ year, term_list = [] } = {}) => ({
          year,
          termList: term_list.map(d => ({
            ID: d?.id,
            name: d?.name,
            from: d?.from,
            to: d?.to,
            year: d?.year,
            termCode: d?.term_code_string,
          })),
        })
      ),
    };
  } catch (ex) {
    DatadogHandler.addError(ex);
    DatadogHandler.sendLog(ex, {}, 'error');
    return {
      data: undefined,
      error: get(ex, 'message', DEFAULT_ERROR),
    };
  }
};

export const msTransformPortfolioById = (...args) => {
  const [
    res,
    childCurrentClasses,
    childLevels,
    childClosedLevels,
    childClasses,
    classTeachersByClasses,
    classTeachers,
    users,
    classes,
    levels,
    children,
    centres,
    terms,
    childLearningGoalEvaluations,
    programs,
    portfolioClassActivities,
    portfolioClassActivityData,
    domains,
  ] = args;
  const classActivities =
    get(portfolioClassActivityData, 'class_activity.data') || [];
  const classActivityLearningGoals =
    get(portfolioClassActivityData, 'class_activity_learning_goal') || {};
  const learningGoals = get(portfolioClassActivityData, 'learning_goal') || {};
  const classActivityImages =
    get(portfolioClassActivityData, 'class_activity_image.data') || [];
  const classActivityDevelopmentalAndLearningAreas =
    get(
      portfolioClassActivityData,
      'class_activity_developmental_and_learning_area.data'
    ) || [];

  return {
    portfolioByID: {
      ...Object.entries(get(res, 'data') || {}).reduce((obj, [key, value]) => {
        obj[camelCase(key)] = value;
        return obj;
      }, {}),
      ID: get(res, 'data.id'),
      status: get(res, 'data.status_string'),
      coverImagePresignedURL: get(res, 'data.cover_image_presigned_url', ''),
      portfolioURL: get(res, 'data.portfolio_url', ''),
      portfolioUrlPresigned: get(res, 'data.portfolio_url_presigned_url', ''),
      portfolioClassTeachers: {
        data: classTeachers.map(d => ({
          ID: d?.id,
          fkClassTeacher: d?.fk_class_teacher,
        })),
      },
      userByRejectedBy: retrieveDataMapper(
        users,
        get(res, 'data.rejected_by'),
        ['id', 'firstname', 'lastname']
      ),
      userByApprovedBy: retrieveDataMapper(
        users,
        get(res, 'data.approved_by'),
        ['id', 'firstname', 'lastname']
      ),
      class: retrieveDataMapper(classes, get(res, 'data.fk_class'), [
        'id',
        'label',
        'fk_level',
      ]),
      level: retrieveDataMapper(levels, get(res, 'data.fk_level'), [
        'id',
        'label',
        'code',
        'from_month',
        'to_month',
      ]),
      term: retrieveDataMapper(terms, get(res, 'data.fk_term'), ['id', 'year']),
      portfolioChildLearningGoalEvaluations: {
        data: childLearningGoalEvaluations.map(d => ({
          ID: d?.id,
          fkChildEvaluation: d?.child_evaluation?.id,
          childLearningGoalEvaluation: {
            ID: d?.child_evaluation?.id,
            fkChild: d?.child_evaluation?.child_id,
            fkLevel: d?.child_evaluation?.level_id,
            fkTerm: d?.child_evaluation?.term_id,
            fkDomain: d?.child_evaluation?.domain?.id,
            parentDomainValue: d?.child_evaluation?.parent_domain_value,
            value: d?.child_evaluation?.value,
            summary: d?.child_evaluation?.summary,
            domain: {
              learningGoalsByClass: {
                data: d?.child_evaluation?.domain?.learning_goal?.data?.map(
                  lg => ({
                    ID: lg?.id,
                    fkDomain: lg?.fk_domain,
                    title: lg?.title,
                    fkLearningGoal: lg?.fk_learning_goal,
                  })
                ),
              },
            },
          },
        })),
      },
      portfolioClassActivities: {
        data: portfolioClassActivities?.map(r => ({
          ID: r?.id,
          fkClassActivity: r?.fk_class_activity,
          fkDomain: r?.fk_domain,
          classActivity: (() => {
            const _clsActivity = retrieveDataMapper(
              classActivities,
              r?.fk_class_activity,
              [
                'id',
                'title',
                'description',
                'interpretation',
                'updated_at',
                'created_at',
                'published_at',
                'display_date',
                'type',
              ]
            );
            if (!_clsActivity) return null;
            return {
              ..._clsActivity,
              classActivityLearningGoals: {
                data: (
                  get(
                    classActivityLearningGoals,
                    `${r?.fk_class_activity}.data`
                  ) || []
                ).map(lg => ({
                  learningGoal: {
                    ID: get(learningGoals, `${lg?.fk_learning_goal}.id`),
                    title: get(learningGoals, `${lg?.fk_learning_goal}.title`),
                  },
                })),
              },
              classActivityImages: {
                data: classActivityImages
                  ?.filter(clI => clI?.fk_class_activity === _clsActivity?.ID)
                  .map(im => ({
                    ID: im?.id,
                    uRL: im?.url,
                    urlPresigned: im?.url_presigned_url,
                    type: im?.type,
                    caption: im?.caption,
                  })),
              },
              classActivityDevelopmentalAndLearningAreas: {
                data: classActivityDevelopmentalAndLearningAreas
                  ?.filter(dva => dva?.fk_class_activity === _clsActivity?.ID)
                  .map(d => ({
                    ID: d?.id,
                    fkDomain: d?.fk_domain,
                    fkClassActivity: d?.fk_class_activity,
                    fkSubDomain: d?.fk_sub_domain,
                    domain: retrieveDataMapper(domains, d?.fk_domain, [
                      'title',
                    ]),
                    learningGoal: retrieveDataMapper(
                      learningGoals,
                      d?.fk_sub_domain,
                      ['title']
                    ),
                  })),
              },
            };
          })(),
        })),
      },
      child: {
        ...retrieveDataMapper(children, get(res, 'data.fk_child'), [
          'id',
          'firstname',
          'lastname',
          'image_key',
          'imageKeyPresignedURL:image_key_presigned_url',
          'date_of_birth',
        ]),
        currentClass: (() => {
          const _class = retrieveDataMapper(
            childCurrentClasses,
            get(res, 'data.fk_child'),
            ['id', 'fk_centre']
          );
          return {
            ..._class,
            class: {
              ...retrieveDataMapper(classes, _class?.ID, ['id', 'label']),
              centre: retrieveDataMapper(centres, get(_class, 'fkCentre'), [
                'label',
              ]),
              classTeachers: {
                data: classTeachersByClasses
                  ?.filter(clt => clt?.fk_class === _class?.ID)
                  ?.map(d => ({
                    user: retrieveDataMapper(users, d?.fk_teacher, [
                      'firstname',
                      'lastname',
                    ]),
                  })),
              },
            },
          };
        })(),
        currentLevel: (() => {
          const childLevel = retrieveDataMapper(
            childLevels,
            get(res, 'data.fk_child'),
            ['fk_level'],
            'fk_child'
          );
          if (!childLevel) return null;
          return {
            level: retrieveDataMapper(levels, childLevel?.fkLevel, [
              'id',
              'label',
              'code',
            ]),
            program: retrieveDataMapper(
              programs,
              childLevel?.fkLevel,
              ['id', 'label'],
              'fk_level'
            ),
          };
        })(),
        closestLevel: (() => {
          const closedLevel = retrieveDataMapper(
            childClosedLevels,
            get(res, 'data.fk_child'),
            ['fk_level'],
            'fk_child'
          );
          const level = retrieveDataMapper(levels, closedLevel?.fkLevel, [
            'id',
            'label',
          ]);
          return level ? { level } : null;
        })(),
        childClasses: {
          data: childClasses.map(d => ({
            ID: d?.id,
            class: (() => {
              const _class = retrieveDataMapper(classes, d?.fk_class, [
                'id',
                'label',
                'fk_centre',
              ]);
              if (!_class) return null;
              return {
                ..._class,
                centre: retrieveDataMapper(centres, _class?.fkCentre, [
                  'label',
                ]),
              };
            })(),
          })),
        },
      },
    },
  };
};

export const msGetPortfolioById = async (_, reqBody = {}) => {
  const id = +get(reqBody, 'id');
  const _classOpsService = classOpsService();
  const _userService = userService();
  const _classService = classService();
  const _schoolService = schoolService();
  const _childService = childService();
  const _centreService = centreSerivce();

  try {
    const res = await _classOpsService.get(GET_PORTFOLIO_BY_ID(id));
    const childId = get(res, 'data.fk_child');
    let childCurrentClasses = {};
    let childLevels = [];
    let childClosedLevels = [];
    let childClasses = [];

    if (childId) {
      [childCurrentClasses, childLevels, childClosedLevels, childClasses] =
        await Promise.all([
          _childService
            .post(...GET_CHILD_CURRENT_CLASS_BY_IDS([childId]))
            .then(res => retrieveListOrObject(res, false))
            .catch(() => ({})),
          _childService
            .post(...GET_LEVEL_BY_CHILD_IDS([childId]))
            .then(retrieveListOrObject)
            .catch(() => []),
          _childService
            .post(...GET_CLOSED_LEVEL_BY_CHILD_IDS([childId]))
            .then(retrieveListOrObject)
            .catch(() => []),
          _childService
            .post(
              ...GET_CHILD_CLASSES_BY_CHILD_IDS(
                [childId],
                {},
                { active: [{ eq: [true, false] }] }
              )
            )
            .then(retrieveListOrObject)
            .catch(() => []),
        ]);
    }
    const termIds = uniq([get(res, 'data.fk_term')])?.filter(f => !!f);
    const programIds = uniq(childLevels.map(d => d?.fk_program))?.filter(
      r => !!r
    );
    const classIds = uniq([
      get(res, 'data.fk_class'),
      ...Object.entries(childCurrentClasses).map(([, _class]) => _class?.id),
      ...childClasses.map(f => f?.fk_class),
    ])?.filter(r => !!r);

    const classTeachersByClasses = await (classIds.length
      ? _classService
          .post(...GET_CLASS_TEACHERS_BY_CLASS_IDS(classIds))
          .then(retrieveListOrObject)
          .catch(() => [])
      : Promise.resolve([]));

    const userIds = uniq([
      get(res, 'data.rejected_by'),
      get(res, 'data.approved_by'),
      ...classTeachersByClasses.map(d => d?.fk_teacher),
    ])?.filter(r => !!r);

    const levelIds = uniq([
      get(res, 'data.fk_level'),
      ...childLevels.map(d => d?.fk_level),
      ...childClosedLevels.map(d => d?.fk_level),
    ])?.filter(r => !!r);

    const centreIds = uniq(
      Object.entries(childCurrentClasses).map(([, _class]) => _class?.fk_centre)
    )?.filter(r => !!r);

    const promises = [
      _classOpsService
        .post(...GET_PORTFOLIO_CLASS_TEACHERS([id]))
        .then(retrieveListOrObject)
        .catch(() => []),
      userIds.length
        ? _userService
            .post(...GET_BY_IDS(userIds))
            .then(retrieveListOrObject)
            .catch(() => [])
        : Promise.resolve([]),
      classIds.length
        ? _classService
            .post(...GET_CLASS_BY_IDS(classIds))
            .then(retrieveListOrObject)
            .catch(() => [])
        : Promise.resolve([]),
      levelIds.length
        ? _schoolService
            .post(...GET_LEVEL_BY_IDS(levelIds))
            .then(retrieveListOrObject)
            .catch(() => [])
        : Promise.resolve([]),
      childId
        ? _childService
            .post(...GET_CHILD_BY_IDS([childId]))
            .then(retrieveListOrObject)
            .catch(() => [])
        : Promise.resolve([]),
      centreIds.length
        ? _centreService
            .post(...GET_CENTRE_BY_IDS(centreIds))
            .then(retrieveListOrObject)
            .catch(() => [])
        : Promise.resolve([]),
      termIds.length
        ? _classOpsService
            .post(...GET_TERM_BY_IDS(termIds))
            .then(retrieveListOrObject)
            .catch(() => [])
        : Promise.resolve([]),
      _classOpsService
        .post(GET_PORTFOLIO_CHILD_LEARNING_GOAL_EVALUATIONS, {
          portfolio_id: id,
          class_id: +get(reqBody, 'classID'),
        })
        .then(retrieveListOrObject)
        .catch(() => []),
      _centreService
        .post(...GET_PROGRAMS_BY_IDS(programIds))
        .then(retrieveListOrObject)
        .catch(() => []),
      _classOpsService
        .post(GET_PORTFOLIO_CLASS_ACTIVITIES, { portfolio_id: id })
        .then(retrieveListOrObject)
        .catch(() => []),
      _classOpsService
        .get(GET_PORTFOLIO_CLASS_ACTIVITY_BY_ID(id))
        .then(res => res?.data || {})
        .catch(() => ({})),
    ];

    const resOfPromises = await Promise.all(promises);
    const classActivityDevelopmentalAndLearningAreas =
      get(
        resOfPromises,
        '[10].class_activity_developmental_and_learning_area.data'
      ) || [];
    const domainIds = uniq(
      classActivityDevelopmentalAndLearningAreas.map(f => get(f, 'fk_domain'))
    ).filter(f => !!f);
    const fkLearningGoals = uniq(
      classActivityDevelopmentalAndLearningAreas?.map(d => d?.fk_sub_domain)
    ).filter(f => !!f);
    const [domains, learningGoals] = await Promise.all([
      domainIds?.length
        ? _classOpsService
            .post(...GET_DOMAINS_BY_DOMAIN_IDS(domainIds))
            .then(retrieveListOrObject)
            .catch(() => [])
        : Promise.resolve([]),
      fkLearningGoals?.length
        ? _classOpsService
            .post(...GET_LEARNING_GOALS_BY_IDS(fkLearningGoals))
            .then(retrieveListOrObject)
            .catch(() => [])
        : Promise.resolve([]),
    ]);

    learningGoals.forEach(d => {
      set(resOfPromises, `[10].learning_goal.${d?.id}`, d);
    });

    return msTransformPortfolioById(
      ...[
        res,
        childCurrentClasses,
        childLevels,
        childClosedLevels,
        childClasses,
        classTeachersByClasses,
        ...resOfPromises,
        domains,
      ]
    );
  } catch (ex) {
    DatadogHandler.addError(ex);
    DatadogHandler.sendLog(ex, {}, 'error');
    return {
      data: undefined,
      error: get(ex, 'message', DEFAULT_ERROR),
    };
  }
};

export const transformChildPortfolio = (...args) => {
  const [
    res,
    childLevels,
    childClosedLevels,
    childClasses,
    childCurrentClasses,
    childClassesAt,
    users,
    children,
    classes,
    levels,
    centres,
    terms,
    programs,
  ] = args;

  return {
    getChildPortfolio: {
      data: (res?.data || []).map(d => ({
        ...Object.entries(d || {}).reduce((obj, [key, value]) => {
          obj[camelCase(key)] = value;
          return obj;
        }, {}),
        ID: d?.id,
        status: get(d, 'status_string', ''),
        coverImagePresignedURL: get(d, 'cover_image_presigned_url', ''),
        portfolioURL: get(d, 'portfolio_url', ''),
        portfolioUrlPresigned: get(d, 'portfolio_url_presigned_url', ''),
        userByRejectedBy: retrieveDataMapper(users, get(d, 'rejected_by'), [
          'id',
          'firstname',
          'lastname',
        ]),
        userByApprovedBy: retrieveDataMapper(users, get(d, 'approved_by'), [
          'id',
          'firstname',
          'lastname',
        ]),
        term: retrieveDataMapper(terms, d?.fk_term, ['id', 'name', 'year']),
        class: retrieveDataMapper(classes, d?.fk_class, [
          'id',
          'label',
          'fk_level',
        ]),
        level: retrieveDataMapper(levels, d?.fk_level, [
          'id',
          'label',
          'code',
          'from_month',
          'to_month',
        ]),
        child: {
          ...retrieveDataMapper(children, d?.fk_child, [
            'id',
            'firstname',
            'lastname',
            'image_key',
            'imageKeyPresignedURL:image_key_presigned_url',
          ]),
          childClassAt: (() => {
            const childClass = retrieveDataMapper(childClassesAt, d?.fk_child, [
              'id',
              'label',
              'fk_centre',
            ]);
            if (!childClass) return null;
            return {
              ID: childClass?.ID,
              class: {
                ...childClass,
                centre: retrieveDataMapper(centres, childClass?.fkCentre, [
                  'label',
                ]),
              },
            };
          })(),
          currentLevel: (() => {
            const childLevel = retrieveDataMapper(
              childLevels,
              d?.fk_child,
              ['fk_level', 'fk_child', 'fk_program'],
              'fk_child'
            );
            if (!childLevel) return null;
            return {
              level: retrieveDataMapper(levels, childLevel?.fkLevel, [
                'id',
                'label',
              ]),
              program: retrieveDataMapper(programs, childLevel?.fkProgram, [
                'id',
                'label',
              ]),
            };
          })(),
          closestLevel: (() => {
            const closedLevel = retrieveDataMapper(
              childClosedLevels,
              d?.fk_child,
              ['fk_level'],
              'fk_child'
            );
            const level = retrieveDataMapper(levels, closedLevel?.fkLevel, [
              'id',
              'label',
            ]);
            return level ? { level } : null;
          })(),
          currentClass: (() => {
            const _class = retrieveDataMapper(
              childCurrentClasses,
              d?.fk_child,
              ['id', 'fk_centre']
            );
            return {
              ..._class,
              class: {
                ...retrieveDataMapper(classes, _class?.ID, ['id', 'label']),
                centre: retrieveDataMapper(centres, get(_class, 'fkCentre'), [
                  'label',
                ]),
              },
            };
          })(),
          childClasses: {
            data: childClasses.map(cls => ({
              ID: cls?.id,
              class: (() => {
                const _class = retrieveDataMapper(classes, cls?.fk_class, [
                  'id',
                  'label',
                  'fk_centre',
                ]);
                if (!_class) return null;
                return {
                  ..._class,
                  centre: retrieveDataMapper(centres, _class?.fkCentre, [
                    'label',
                  ]),
                };
              })(),
            })),
          },
        },
      })),
      totalCount: get(res, 'totalCount', 0),
    },
  };
};

export const msGetChildPortfolio = async (_, reqBody = {}) => {
  try {
    const signal = get(reqBody, 'controller.signal', null);
    const _classOpsService = classOpsService();
    const _userService = userService();
    const _classService = classService();
    const _schoolService = schoolService();
    const _childService = childService();
    const _centreService = centreSerivce();

    let childCurrentClasses = {};
    let childLevels = [];
    let childClosedLevels = [];
    let childClasses = [];
    let childClassesAt = {};

    const body = {
      child_ids: get(reqBody, 'ChildID', []),
      child_status: get(reqBody, 'childStatus'),
      child_portfolio_custom_filter: {
        ...(get(reqBody, 'customFilter.fkCentre') && {
          fk_centre: get(reqBody, 'customFilter.fkCentre', []),
        }),
        ...(get(reqBody, 'customFilter.fkClass') && {
          fk_class: get(reqBody, 'customFilter.fkClass', []),
        }),
        ...(get(reqBody, 'customFilter.fkLevel') && {
          fk_level: get(reqBody, 'customFilter.fkLevel', []),
        }),
        ...(get(reqBody, 'customFilter.fkProgram') && {
          fk_program: get(reqBody, 'customFilter.fkProgram', []),
        }),
        ...(get(reqBody, 'customFilter.status') && {
          status: get(reqBody, 'customFilter.status', []),
        }),
        ...(get(reqBody, 'customFilter.fkTerm') && {
          fk_term: get(reqBody, 'customFilter.fkTerm', []),
        }),
        ...(get(reqBody, 'customFilter.year') && {
          year: get(reqBody, 'customFilter.year', []),
        }),
      },
    };

    const res = await _classOpsService
      .post(
        `${GET_CHILD_PORTFOLIO}?${new URLSearchParams({
          page: +get(reqBody, 'pagination.page') || 1,
          perPage: +get(reqBody, 'pagination.perPage') || 10,
          ...retrieveSortForMS(get(reqBody, 'pagination.sort')),
        })}`,
        body,
        { signal }
      )
      .then(res => ({
        data: retrieveListOrObject(res),
        totalCount: get(res, 'data.totalCount', 0),
      }))
      .catch(() => []);

    const termIds = uniq(res?.data?.map(r => r?.fk_term))?.filter(f => !!f);
    const childIds = uniq(res?.data?.map(r => r?.fk_child))?.filter(f => !!f);
    const userIds = uniq(
      res?.data
        ?.filter(r => !!get(r, 'rejected_by') || !!get(r, 'approved_by'))
        .map(u => get(u, 'rejected_by') || get(u, 'approved_by'))
    )?.filter(r => !!r);

    if (childIds?.length) {
      [
        childCurrentClasses,
        childLevels,
        childClosedLevels,
        childClasses,
        childClassesAt,
      ] = await Promise.all([
        _childService
          .post(...GET_CHILD_CURRENT_CLASS_BY_IDS(childIds))
          .then(res => retrieveListOrObject(res, false))
          .catch(() => ({})),
        _childService
          .post(...GET_LEVEL_BY_CHILD_IDS(childIds))
          .then(retrieveListOrObject)
          .catch(() => []),
        _childService
          .post(...GET_CLOSED_LEVEL_BY_CHILD_IDS(childIds))
          .then(retrieveListOrObject)
          .catch(() => []),
        _childService
          .post(
            ...GET_CHILD_CLASSES_BY_CHILD_IDS(
              childIds,
              {},
              { active: [{ eq: [true, false] }] }
            )
          )
          .then(retrieveListOrObject)
          .catch(() => []),
        _childService
          .post(
            ...GET_CHILD_CLASSES_AT_BY_CHILD_IDS(childIds, get(reqBody, 'time'))
          )
          .then(res => retrieveListOrObject(res, false))
          .catch(() => ({})),
      ]);
    }
    const programIds = uniq(childLevels.map(d => d?.fk_program))?.filter(
      r => !!r
    );
    const levelIds = uniq([
      ...(res?.data || []).map(r => r?.fk_level),
      ...childLevels.map(r => r?.fk_level),
      ...childClosedLevels.map(r => r?.fk_level),
    ])?.filter(f => !!f);

    const classIds = uniq([
      ...(res?.data || []).map(r => r?.fk_class),
      ...Object.entries(childCurrentClasses).map(([, _class]) => _class?.id),
      ...Object.entries(childClassesAt).map(([, _class]) => _class?.id),
    ])?.filter(f => !!f);

    const centreIds = uniq([
      ...(res?.data || []).map(r => r?.fk_centre),
      ...Object.entries(childCurrentClasses).map(
        ([, _class]) => _class?.fk_centre
      ),
      ...Object.entries(childClassesAt).map(([, _class]) => _class?.fk_centre),
    ])?.filter(f => !!f);

    const promises = [
      userIds.length
        ? _userService
            .post(...GET_BY_IDS(userIds))
            .then(retrieveListOrObject)
            .catch(() => [])
        : Promise.resolve([]), // users by ids
      childIds?.length
        ? _childService
            .post(...GET_CHILD_BY_IDS(childIds))
            .then(retrieveListOrObject)
            .catch(() => [])
        : Promise.resolve([]), // children by ids
      classIds.length
        ? _classService
            .post(...GET_CLASS_BY_IDS(classIds))
            .then(retrieveListOrObject)
            .catch(() => [])
        : Promise.resolve([]), // classes by ids
      levelIds.length
        ? _schoolService
            .post(...GET_LEVEL_BY_IDS(levelIds))
            .then(retrieveListOrObject)
            .catch(() => [])
        : Promise.resolve([]), // levels by ids
      centreIds.length
        ? _centreService
            .post(...GET_CENTRE_BY_IDS(centreIds))
            .then(retrieveListOrObject)
            .catch(() => [])
        : Promise.resolve([]), // centres by ids
      termIds.length
        ? _classOpsService
            .post(...GET_TERM_BY_IDS(termIds))
            .then(retrieveListOrObject)
            .catch(() => [])
        : Promise.resolve([]), // terms by ids
      programIds?.length
        ? _centreService
            .post(...GET_PROGRAMS_BY_IDS(programIds))
            .then(retrieveListOrObject)
            .catch(() => [])
        : Promise.resolve([]), // programs by ids
    ];

    const resOfPromises = await Promise.all(promises);
    return transformChildPortfolio(
      ...[
        res,
        childLevels,
        childClosedLevels,
        childClasses,
        childCurrentClasses,
        childClassesAt,
        ...resOfPromises,
      ]
    );
  } catch (ex) {
    DatadogHandler.addError(ex);
    DatadogHandler.sendLog(ex, {}, 'error');
    return {
      error:
        get(ex, 'response.data.error') || get(ex, 'message', DEFAULT_ERROR),
    };
  }
};

export const msBulkPublishPortfolios = async (_, reqBody = {}) => {
  try {
    const portfolioIds = get(reqBody, 'id') || [];
    const res = await classOpsService().post(
      ...BULK_PUBLISH_PORTFOLIOS(portfolioIds)
    );
    return res;
  } catch (ex) {
    DatadogHandler.addError(ex);
    DatadogHandler.sendLog(ex, {}, 'error');
    return {
      data: undefined,
      errors: [get(ex, 'message', DEFAULT_ERROR)],
    };
  }
};

export const msTransformGetListDomainForPortfolio = (...args) => {
  const [res, learningGoals, evaluationLabels] = args;
  function getLearningGoalsByClass(domainId) {
    let learningGoalsByClass = [];
    const learningGoalsByDomain = learningGoals.filter(
      lg => lg?.fk_domain === domainId
    );
    const parentLearningGoals = learningGoalsByDomain.filter(
      lg => !lg?.fk_learning_goal
    );
    parentLearningGoals.forEach(lg => {
      const lgData = [
        lg,
        ...sortBy(
          learningGoalsByDomain.filter(it => it?.fk_learning_goal === lg?.id),
          'id'
        ),
      ];
      learningGoalsByClass = learningGoalsByClass.concat(lgData);
    });
    return learningGoalsByClass;
  }

  return {
    getListDomain: {
      totalCount: res?.totalCount,
      data: res?.data?.map(d => ({
        ...retrieveObjectMapper(d, [
          'ID:id',
          'title',
          'is_chd_portfolio_domain',
        ]),
        learningGoalsByClass: (() => ({
          data: getLearningGoalsByClass(d?.id)?.map(lg =>
            retrieveObjectMapper(lg, ['ID:id', 'title', 'fk_learning_goal'])
          ),
        }))(),
        evaluationLabels: (() => ({
          data: evaluationLabels
            ?.filter(evl => evl?.fk_domain === d?.id)
            ?.map(evl =>
              retrieveObjectMapper(evl, ['ID:id', 'title', 'index'])
            ),
        }))(),
      })),
    },
  };
};

// This is common API function that can be re-use from Class Activity module
export const msGetListDomain = async (reqBody = {}) => {
  try {
    const classId = +get(reqBody, 'classID');
    const levelId = +get(reqBody, 'fkLevel');
    const version = +get(reqBody, 'version');

    const isCHDPortfolioDomain = get(reqBody, 'isCHDPortfolioDomain');
    const _classOpsService = classOpsService();

    const body = {
      class_id: classId,
      ...(levelId ? { level_id: [levelId] } : {}),
      ...(get(reqBody, 'childID')
        ? { child_id: +get(reqBody, 'childID') }
        : {}),
      ...(isCHDPortfolioDomain !== undefined
        ? { is_chd_portfolio_domain: isCHDPortfolioDomain }
        : {}),
      ...(version ? { version } : {}),
    };
    const res = await _classOpsService
      .post(GET_LIST_DOMAIN, body)
      .then(res => ({
        totalCount: get(res, 'data.totalCount') ?? 0,
        data: retrieveListOrObject(res),
      }))
      .catch(() => ({
        totalCount: 0,
        data: [],
      }));

    const domainIds = uniq(res?.data?.map(d => d?.id)).filter(f => !!f);

    const [learningGoals, evaluationLabels] = await Promise.all([
      _classOpsService
        .post(
          `${GET_LEARNING_GOALS_BY_CLASS}?${new URLSearchParams({
            page: 1,
            perPage: 500,
          })}`,
          {
            class_id: classId,
            filter: {
              fk_domain: [
                {
                  eq: domainIds,
                },
              ],
              // ...(version ? { version: [{ eq: version?.toString() }] } : {}),
            },
          }
        )
        .then(retrieveListOrObject)
        .catch(() => []),
      get(reqBody, 'fkLevel')
        ? _classOpsService
            .get(GET_EVALUATION_LABELS(+get(reqBody, 'fkLevel')))
            .then(retrieveListOrObject)
            .catch(() => [])
        : Promise.resolve([]),
    ]);

    const learningGoalsId =
      get(learningGoals, 'length', 0) > 0 &&
      uniq(learningGoals.map(d => d?.id)).filter(f => !!f);

    const learningGoalsData = await _classOpsService.post(
      `${GET_LEARNING_GOALS_BY_CLASS}?${new URLSearchParams({
        page: 1,
        perPage: 500,
      })}`,
      {
        class_id: classId,
        filter: {
          // ...(version && {
          //   version: [
          //     {
          //       eq: version,
          //     },
          //   ],
          // }),
          ...(learningGoalsId.length > 0 && {
            fk_learning_goal: [
              {
                eq: learningGoalsId,
              },
            ],
          }),
        },
      }
    );

    return [
      res,
      learningGoals,
      evaluationLabels,
      get(learningGoalsData, 'data', []),
    ];
  } catch (ex) {
    DatadogHandler.addError(ex);
    DatadogHandler.sendLog(ex, {}, 'error');
    return [{}, [], []];
  }
};

export const msGetListDomainForPortfolio = async (_, reqBody = {}) => {
  try {
    const res = await msGetListDomain(reqBody);
    return msTransformGetListDomainForPortfolio(...res);
  } catch (ex) {
    DatadogHandler.addError(ex);
    DatadogHandler.sendLog(ex, {}, 'error');
    return {
      error:
        get(ex, 'response.data.error') || get(ex, 'message', DEFAULT_ERROR),
    };
  }
};

export const msSavePortfolioURL = async (_, reqBody = {}) => {
  try {
    const body = {
      file: {
        mime_type: reqBody?.file?.mimetype,
        file_size: reqBody?.file?.filesize,
        file_name: reqBody?.file?.filename,
      },
    };
    const res = await classOpsService().post(GET_PORTFOLIO_UPLOAD_URL, body);
    return {
      data: {
        getPortfolioPdfUploadURL: get(res, 'data', {}),
      },
    };
  } catch (ex) {
    DatadogHandler.addError(ex);
    DatadogHandler.sendLog(ex, {}, 'error');
    return {
      errors: [
        get(ex, 'response.data.error') || get(ex, 'message', DEFAULT_ERROR),
      ],
    };
  }
};

export const msGetHistoricalCentreAndClassesOfChild = async (
  _,
  reqBody = {}
) => {
  try {
    const _centreService = centreSerivce();
    const result = await _centreService
      .post(
        ...GET_CHILD_HISTORICAL_CENTRE_CLASS(
          get(reqBody, 'childId'),
          get(reqBody, 'centreId')
        )
      )
      .then(res => get(res, 'data', {}))
      .catch(() => []);

    return {
      GetHistoricalCentreAndClassesofChild: {
        Items: (get(result, 'items') || []).map(item => {
          const levels = get(item, 'levels') || [];
          return {
            ...retrieveObjectMapper(item, ['ID:id', 'Label:label']),
            Levels: (() =>
              levels.map(lv => {
                const classes = get(lv, 'classes') || [];
                return {
                  ...retrieveObjectMapper(lv, ['ID:id', 'Label:label']),
                  Classes: (() =>
                    classes.map(cls =>
                      retrieveObjectMapper(cls, [
                        'ID:id',
                        'Label:label',
                        'From:from',
                        'To:to',
                      ])
                    ))(),
                };
              }))(),
          };
        }),
      },
    };
  } catch (ex) {
    DatadogHandler.addError(ex);
    DatadogHandler.sendLog(ex, {}, 'error');
    return {
      errors: [
        get(ex, 'response.data.error') || get(ex, 'message', DEFAULT_ERROR),
      ],
    };
  }
};

export default {
  getSelector,
  handleChange,
  handleChangeChildNameFilter,
  handlePageChanged,
  onClear,
  fetchHistoricalChildData,
  onCancelBulkPublishSelection,
  bulkPublishPortfolios,
  navigatesToPortfolioCreatePage,
  handleAutoCompleteChange,
  enableBulkPublish,
  getPortfoliosWithFilter,
  variableBody,
  closeAddPortfolioModal,
  getFilterSource,
  msDeletePortfolio,
  msSavePortfolio,
  msChangePortfolioStatus,
  msGetPortfolioPhotoUploadURL,
  msSyncChildAOIAndRecommendation,
  msGetPortfolioTermByYear,
  msGetPortfolioById,
  msGetChildPortfolio,
  msGetListDomain,
};

import { get, cloneDeep } from 'lodash';
import {
  FETCH_ENQUIRY_BY_ID,
  FETCH_UPLOADED_DOCUMENTS,
  FETCH_USER_DOCUMENTS,
  FETCH_ADDITIONAL_INFO,
  FETCH_CONSENT_FORM_SIGNER,
  REG_ENROLMENT_FORM,
  FETCH_ENQUIRY_BY_ID_ECDAMVP,
  ECDA_REG_ENROLMENT_FORM,
} from '../actions/actionTypes';
import { bitwiseAndCheck } from '../../utils';
import {
  REGISTRATION_FORM,
  DOCUMENT_ACCESS_PARENT,
  DOCUMENT_ACCESS_STAFF_PARENT,
  FORM_1_OFFLINE_CONSENT,
} from '../../utils/constants';

export const uploadedDocumentList = (state = {}, action) => {
  if (action.type === FETCH_UPLOADED_DOCUMENTS) {
    const data = get(action, 'value.data.data.getUploadedDocument.data');
    let newState = cloneDeep(state);
    newState = { ...action.value };
    if (data) {
      newState.data = data;
    }
    return newState;
  }

  return state;
};

/**
 *Example data
 * {
 *   "data": {
 *     "listRequiredDocuments": []
 *   }
 * }
 */

export const documentListing = (state = {}, action) => {
  if (action.type === FETCH_USER_DOCUMENTS) {
    const data = get(action, 'value.data.data.listRequiredDocuments');
    if (data) {
      const reqDocs = Object.assign([], data);
      const modeledDocs = [];

      reqDocs.forEach((eachDoc, idx) => {
        if (
          bitwiseAndCheck(eachDoc.UploadAccess, DOCUMENT_ACCESS_PARENT) ||
          bitwiseAndCheck(eachDoc.UploadAccess, DOCUMENT_ACCESS_STAFF_PARENT) ||
          eachDoc.DocumentType === REGISTRATION_FORM ||
          eachDoc.DocumentType === FORM_1_OFFLINE_CONSENT
        ) {
          modeledDocs.push({
            ID: idx + 1,
            action: 'Select Files',
            uploadedFiles: [],
            fileError: '',
            parentName: eachDoc.Fullname,
            document_type: eachDoc.DocumentType,
            parentIdNo: eachDoc.ParentID,
            childIdNo: eachDoc.ChildID,
            docTypeId: eachDoc.DocumentTypeID,
            status: eachDoc.Status,
            remarks: eachDoc.Remarks,
          });
        }
      });

      return Object.assign({}, state, {
        ...action.value,
        data: modeledDocs,
      });
    }
    return Object.assign({}, state, action.value);
  }

  return state;
};

/**
 *
 * Example data
 *
 * {
 *  "data": {
 *    "enquiryByID": {
 *      "ID": 53,
 *      "fkCentre": 1,
 *      "fkUser": 997,
 *      "fkLevel": 17,
 *      "fkProgram": 10,
 *      "enrolmentStep": null,
 *      "fkEnquiryState": "waiting_list",
 *      "firstname": "",
 *      "lastname": "Li Jing",
 *      "consentStatus": "pending",
 *      "documentStatus": "pending",
 *      "enrollmentPlan": "2020-01-01 00:00:00",
 *      "createdAt": "2019-05-04 00:00:00",
 *      "updatedAt": "2019-12-01 12:51:02",
 *      "enrolmentStartDate": null,
 *      "householdIncomeRange": null,
 *      "active": true,
 *      "paymentStatus": "pending",
 *      "giroStatus": "pending",
 *      "vacancyAvailable": false,
 *      "privateVacancyAvailable": true,
 *      "isTransferEnquiry": false,
 *      "isTransferEnquiryReadyForEnrolment": false,
 *      "applicationSubmitted": false,
 *      "admissionStatus": {
 *        "label": "Enquire",
 *        "stateCode": "enquire"
 *      },
 *      "child": {
 *        "ID": 634,
 *        "firstname": "",
 *        "lastname": "Ethan Zeng Shaohang",
 *        "race": null,
 *        "nationality": "citizen",
 *        "dateOfBirth": "2018-01-03 00:00:00",
 *        "gender": "f",
 *        "birthCertificate": "T1800180I"
 *      },
 *      "centre": {
 *        "ID": 1,
 *        "label": "The Caterpillar's Cove @ Jurong East",
 *        "code": "TC2",
 *        "payNowEmail": "",
 *        "addresses": {
 *          "totalCount": 1,
 *          "data": [
 *            {
 *              "ID": 33,
 *              "name": "Cove @ Jurong East",
 *              "address": "80, Jurong East Street 21",
 *              "postcode": "609607",
 *              "fkCentre": 1,
 *              "lat": 1.3358266,
 *              "lng": 103.7428715,
 *              "building": "",
 *              "floor": "03",
 *              "unit": "02",
 *              "city": "Singapore"
 *            }
 *          ]
 *        }
 *      },
 *      "visits": {
 *        "totalCount": 1,
 *        "data": [
 *          {
 *            "ID": 53,
 *            "status": "completed",
 *            "visitTo": "2019-11-01 12:30:00",
 *            "visitFrom": "2019-11-01 12:00:00",
 *            "centre": {
 *              "label": "The Caterpillar's Cove @ Jurong East"
 *            }
 *          }
 *        ]
 *      },
 *      "program": {
 *        "ID": 10,
 *        "label": "Full Day"
 *      },
 *      "user": {
 *        "firstname": "",
 *        "lastname": "Li Jing",
 *        "ID": 997,
 *        "gender": "f",
 *        "mobilePhone": "40000997",
 *        "mobilePhoneCountryCode": "+65000",
 *        "homePhone": "40000997",
 *        "email": "sn2dummy+40000997@gmail.com",
 *        "addresses": {
 *          "totalCount": 1,
 *          "data": [
 *            {
 *              "fkUser": 997,
 *              "address": "",
 *              "building": null,
 *              "floor": "",
 *              "unit": "",
 *              "city": "Singapore",
 *              "country": "Singapore",
 *              "postcode": null
 *            }
 *          ]
 *        },
 *        "parent": {
 *          "identificationNo": null
 *        }
 *      },
 *      "level": {
 *        "ID": 17,
 *        "label": "Junior Toddlers"
 *      },
 *      "forms": {
 *        "data": []
 *      }
 *    }
 *  }
 *}
 */

export const enquiryDetailById = (state = {}, action) => {
  if (action.type === FETCH_ENQUIRY_BY_ID) {
    const data = get(action, 'value.data.data');
    if (data) {
      return Object.assign({}, state, {
        ...action.value,
        data,
      });
    }
    return Object.assign({}, state, action.value);
  }
  return state;
};

export const ecdaRegistrationByID = (state = {}, action) => {
  if (action.type === FETCH_ENQUIRY_BY_ID_ECDAMVP) {
    const data = get(action, 'value.data.data');
    if (data) {
      return Object.assign({}, state, {
        ...action.value,
        data,
      });
    }
    return Object.assign({}, state, action.value);
  }
  return state;
};

/**
 *Example data
 *
 * {
 *  "data": {
 *    "getAdditionalInformation": {
 *      "attendingOtherSchool": false,
 *      "specialInstruction": null,
 *      "houseHoldParticulars": {
 *        "householdCount": 3,
 *        "fatherEducationLevel": "no_qualification",
 *        "fatherEducationOthers": "",
 *        "motherEducationLevel": "no_qualification",
 *        "motherEducationOthers": ""
 *      },
 *      "healthInformation": {
 *        "isImmunized": false,
 *        "immunizationDetails": "",
 *        "familyDoctorDetails": {
 *          "name": "bnbvvnbvnvbn",
 *          "contactNumber": "89562375",
 *          "countryCode": "+247",
 *          "clinicName": "Some clinic",
 *          "clinicBuilding": "blah@blah.com",
 *          "clinicFloor": "",
 *          "clinicUnit": "",
 *          "clinicCity": "",
 *          "clinicPostalCode": "588932"
 *        },
 *        "medicalCondition": [
 *          {
 *            "disease": "fits",
 *            "exists": false,
 *            "remarks": ""
 *          },
 *          {
 *            "disease": "asthma",
 *            "exists": false,
 *            "remarks": ""
 *          },
 *          {
 *            "disease": "diabetes",
 *            "exists": false,
 *            "remarks": ""
 *          },
 *          {
 *            "disease": "hepatitis_b",
 *            "exists": false,
 *            "remarks": ""
 *          },
 *          {
 *            "disease": "hiv",
 *            "exists": false,
 *            "remarks": ""
 *          },
 *          {
 *            "disease": "allergy",
 *            "exists": false,
 *            "remarks": ""
 *          }
 *        ],
 *        "medicalConditionRemarks": "",
 *        "specialNeeds": false
 *      },
 *      "declaration": {
 *        "termsAndCondition": true,
 *        "termsCDN": false
 *      }
 *    }
 *  }
 *}
 */

export const additionalInfo = (state = {}, action) => {
  if (action.type === FETCH_ADDITIONAL_INFO) {
    const data = get(action, 'value.data.data.getAdditionalInformation');

    if (data) {
      return Object.assign({}, state, {
        ...action.value,
        data,
      });
    }

    return Object.assign({}, state, action.value);
  }

  return state;
};

/**
 *Example data
 *
 * {
 * "data": {
 *    "getEnrolmentForm": {
 *      "Step": 0,
 *      "IDSchool": 0,
 *      "Enquiry": {
 *        "enrollmentPlan": "2021-01-01 00:00:00",
 *        "applyFinancialAssistance": false,
 *        "householdIncomeRange": null,
 *        "hasSug": false,
 *        "hasFinancialAssistance": false,
 *        "typeOfReferral": null,
 *        "referralChannel": null,
 *        "financialStartDate": null,
 *        "financialEndDate": null,
 *        "isDeclared": false,
 *        "isAcknowledged": false,
 *        "otherReferralChannel": null
 *      },
 *      "ChildParticulars": {
 *        "IDChild": 586,
 *        "Child": {
 *          "firstname": "",
 *          "lastname": "Hazel Felise Lim",
 *          "nationality": "citizen",
 *          "gender": "f",
 *          "race": null,
 *          "dateOfBirth": "2020-01-23 00:00:00",
 *          "birthCertificate": "T2002208B",
 *          "numFamilyMembers": 0,
 *          "numDependents": 0,
 *          "isBigHousehold": false,
 *          "hasEmergencyContact": false,
 *          "supportScheme": null
 *        }
 *      },
 *      "Applicant": {
 *        "Applicant": {
 *          "firstname": "",
 *          "lastname": "Chai Li Fah",
 *          "email": "sn2dummy+40000664@gmail.com",
 *          "mobilePhoneCountryCode": "+65000",
 *          "mobilePhone": "40000664",
 *          "birthdate": "1985-01-18 00:00:00",
 *          "gender": "f"
 *        },
 *        "ApplicantAddress": {
 *          "address": "Jurong East Street 24",
 *          "building": "264",
 *          "floor": "02",
 *          "unit": "507",
 *          "postcode": "600264"
 *        },
 *        "ApplicantDetail": {
 *          "firstname": "",
 *          "lastname": "Chai Li Fah",
 *          "email": "sn2dummy+40000664@gmail.com",
 *          "mobilePhoneCountryCode": "+65000",
 *          "mobilePhone": "40000664",
 *          "birthdate": "1985-01-18 00:00:00",
 *          "gender": "f",
 *          "typeOfSeparation": null,
 *          "identificationNo": "S8578597D",
 *          "monthlyIncome": null,
 *          "selfEmployedMonthlyIncome": null,
 *          "workingStatus": "salaried_employee",
 *          "maritalStatus": "married",
 *          "highestQualification": "university",
 *          "occupationalTitle": "professionals",
 *          "nationality": "pr",
 *          "isEmployed": true,
 *          "selfEmployed": false,
 *          "isRecentlyEmployed": false,
 *          "reasonOfUnemployment": null,
 *          "referredToWsg": false,
 *          "isApplicantSpouse": false,
 *          "commencementDate": "2015-01-01 00:00:00",
 *          "permanentResidenceStartDate": null,
 *          "hasNoa": false,
 *          "hasCpf": false,
 *          "isNameInBc": false
 *        },
 *        "ApplicantRelationshipToChild": {
 *          "fkRelation": 979,
 *          "fkChild": 586
 *        },
 *        "ApplicantGuardian": null
 *      },
 *      "Spouse": {
 *        "Applicant": {
 *          "firstname": "",
 *          "lastname": "Hadi Kusumo Halim",
 *          "email": "sn2dummy+40000665@gmail.com",
 *          "mobilePhoneCountryCode": "+65000",
 *          "mobilePhone": "40000665",
 *          "birthdate": "1981-09-11 00:00:00",
 *          "gender": "m"
 *        },
 *        "ApplicantAddress": {
 *          "address": "Jurong East Street 24",
 *          "building": "264",
 *          "floor": "02",
 *          "unit": "507",
 *          "postcode": "600264"
 *        },
 *        "ApplicantDetail": {
 *          "firstname": "",
 *          "lastname": "Hadi Kusumo Halim",
 *          "email": "sn2dummy+40000665@gmail.com",
 *          "mobilePhoneCountryCode": "+65000",
 *          "mobilePhone": "40000665",
 *          "birthdate": "1981-09-11 00:00:00",
 *          "gender": "m",
 *          "identificationNo": "S8182685D",
 *          "monthlyIncome": null,
 *          "selfEmployedMonthlyIncome": null,
 *          "workingStatus": "salaried_employee",
 *          "maritalStatus": "married",
 *          "highestQualification": "university",
 *          "occupationalTitle": "professionals",
 *          "nationality": "citizen",
 *          "isEmployed": true,
 *          "selfEmployed": false,
 *          "isRecentlyEmployed": false,
 *          "reasonOfUnemployment": null,
 *          "referredToWsg": false,
 *          "isApplicantSpouse": false,
 *          "commencementDate": null,
 *          "permanentResidenceStartDate": null,
 *          "hasNoa": false,
 *          "hasCpf": false
 *        },
 *        "ApplicantRelationshipToChild": {
 *          "fkRelation": 980,
 *          "fkChild": 586
 *        },
 *        "ApplicantGuardian": null
 *      },
 *      "Guardians": [],
 *      "EmergencyContact": null,
 *      "DefaultEmergencyContact": {
 *        "firstName": "",
 *        "lastName": "",
 *        "identificationNo": "",
 *        "mobilePhone": "",
 *        "mobilePhoneCC": "",
 *        "postalCode": "",
 *        "address": "",
 *        "floorNo": null,
 *        "unitNo": null,
 *        "relationship": 0
 *      }
 *    }
 *  }
 *}
 *
 */
export function enrolmentForm(state = {}, action) {
  if (action.type === REG_ENROLMENT_FORM) {
    const error = action.value.error;
    if (error) {
      return Object.assign({}, state, {
        error,
      });
    }
    return Object.assign({}, action.value);
  }
  return state;
}

export function ecdaEnrolmentForm(state = {}, action) {
  if (action.type === ECDA_REG_ENROLMENT_FORM) {
    const error = action.value.error;
    if (error) {
      return Object.assign({}, state, {
        error,
      });
    }
    return Object.assign({}, action.value);
  }
  return state;
}

/**
 *Example data
 * {
 *   "data": {
 *     "getFormSigner": [
 *       {
 *         "DisplayName": "Chai Li Fah (Main applicant)",
 *         "Email": "sn2dummy+40000664@gmail.com",
 *         "Order": 1,
 *         "ParentID": 665,
 *         "UserId": 664,
 *         "Status": "pending",
 *         "IsStaff": false
 *       },
 *     ]
 *   }
 * }
 */

export const getFormSigner = (state = {}, action) => {
  if (action.type === FETCH_CONSENT_FORM_SIGNER) {
    const data = get(action, 'value.data');
    if (data) {
      return Object.assign({}, state, {
        ...action.value,
        data,
      });
    }
    return Object.assign({}, state, action.value);
  }
  return state;
};

export default enquiryDetailById;
